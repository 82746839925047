import { getProportionalPrice } from "../../shared/util/getProportionalPrice";
import { StringNumbers } from "../../shared/data/types";

export const getPlanPriceById = (
  planId: StringNumbers,
  loadedPrices: {
    simple: number;
    crm: number;
    enterprise: number;
  },
  discountMultiplier: number | null,
  vendorsAmount: number = 2,
  isUpgrade: boolean = false,
  validityDate?: string,
  currentAcVendors: number = 1
) => {
  if (planId === "1") {
    return !!discountMultiplier
      ? loadedPrices.simple * discountMultiplier
      : loadedPrices.simple;
  } else if (planId === "2") {
    return !!discountMultiplier
      ? loadedPrices.crm * discountMultiplier
      : loadedPrices.crm;
  } else if (planId === "3") {
    if (!isUpgrade) {
      return !!discountMultiplier
        ? loadedPrices.enterprise * discountMultiplier * vendorsAmount
        : loadedPrices.enterprise * vendorsAmount;
    } else {
      return getProportionalPrice(
        loadedPrices.enterprise,
        discountMultiplier || 1,
        vendorsAmount - currentAcVendors,
        validityDate
      );
    }
  }
};
