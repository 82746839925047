import { useEffect, useState } from "react";
import { Chart, PieSeries } from "@devexpress/dx-react-chart-material-ui";
import { Animation } from "@devexpress/dx-react-chart";
import { Palette } from "@devexpress/dx-react-chart";

import { useHttpClient } from "../../../shared/hooks/httpHook";
import { ModalError } from "../../../shared/components/UIElements/ModalError";
import { Help } from "../../../shared/components/UIElements/Help";
import { LoadingSpinnerOverlayRegular } from "../../../shared/components/UIElements/LoadingSpinnerOverlayRegular";
import { addThousandSeparator } from "../../../shared/util/addThousandSeparator";
import { getMonthNameAndYear } from "../../../shared/util/getMonthNameAndYear";
import { getSalesInprogress } from "../../api/analyticsAPI";
import {
  AuthContextProps,
  SettingsContextProps,
} from "../../../shared/data/types";

import "./HomeDashboard.scss";

interface HomeDashboardSalesInProgressProps {
  settingsCtx: SettingsContextProps;
  auth: AuthContextProps;
  fetchId: string;
  loadedData: {
    totalSales: number;
    totalAmount: number;
    totalPotProfit: number;
  };
  setLoadedData: React.Dispatch<
    React.SetStateAction<{
      totalSales: number;
      totalAmount: number;
      totalPotProfit: number;
    }>
  >;
  setIsLoadingClosedSales: React.Dispatch<React.SetStateAction<boolean>>;
}

export const HomeDashboardSalesInProgress = (
  props: HomeDashboardSalesInProgressProps
) => {
  const {
    settingsCtx,
    auth,
    fetchId,
    loadedData,
    setLoadedData,
    setIsLoadingClosedSales,
  } = props;
  const { error, sendRequest, clearError, isLoading } = useHttpClient();

  useEffect(() => {
    if (!!auth.token) {
      getSalesInprogress({ sendRequest, auth, setLoadedData, fetchId });
    }
  }, [fetchId, auth?.token]);

  useEffect(() => {
    setIsLoadingClosedSales(isLoading);
  }, [isLoading]);

  return (
    <div className="dashboard-item dashboard-item--sales-in-progress">
      {isLoading && <LoadingSpinnerOverlayRegular />}
      {/* <ModalError error={error} onClear={clearError} /> */}
      <p className="dashboard-item__title">Negócios em Aberto</p>
      <div className="dashboard-item__img-container">
        <img
          className="dashboard-item__img"
          src={`${process.env.PUBLIC_URL}/azume-sales-funnel.png`}
          alt="Funil de Vendas"
        />
      </div>
      <div className="dashboard-item__legends">
        <div className="dashboard-item__legend">
          {/* <div className="dashboard-item__legend-color dashboard-item__legend-color--1"></div> */}
          <div className="dashboard-item__legend-data">
            <p className="dashboard-item__legend-value">
              {addThousandSeparator(loadedData?.totalSales || 0, false, false)}
            </p>
            <p className="dashboard-item__legend-period">Negócios</p>
          </div>
        </div>
        <div className="dashboard-item__legend">
          {/* <div className="dashboard-item__legend-color dashboard-item__legend-color--2"></div> */}
          <div className="dashboard-item__legend-data">
            <p className="dashboard-item__legend-value">
              R${" "}
              {
                addThousandSeparator(loadedData?.totalAmount || 0)?.split(
                  ","
                )?.[0]
              }
              <span className="dashboard-item__legend-value--cents">
                {
                  addThousandSeparator(loadedData?.totalAmount || 0)?.split(
                    ","
                  )?.[1]
                }
              </span>
            </p>
            <p className="dashboard-item__legend-period">Valor em Aberto</p>
          </div>
        </div>
        <div className="dashboard-item__legend">
          {/* <div className="dashboard-item__legend-color dashboard-item__legend-color--3"></div> */}
          <div className="dashboard-item__legend-data">
            {settingsCtx?.autoPricingType === "DETAILED" && (
              <p className="dashboard-item__legend-value">
                R${" "}
                {
                  addThousandSeparator(loadedData?.totalPotProfit || 0)?.split(
                    ","
                  )?.[0]
                }
                <span className="dashboard-item__legend-value--cents">
                  {
                    addThousandSeparator(
                      loadedData?.totalPotProfit || 0
                    )?.split(",")?.[1]
                  }
                </span>
              </p>
            )}
            {settingsCtx?.autoPricingType !== "DETAILED" && (
              <p className="dashboard-item__legend-value dashboard-item__legend-value--help">
                -&nbsp;&nbsp;&nbsp;
                <Help content="Este dado só é disponibilizado para contas que utlizam a PRECIFICAÇÃO DETALHADA" />
              </p>
            )}
            <p className="dashboard-item__legend-period">Lucro Potential</p>
          </div>
        </div>
      </div>
    </div>
  );
};
