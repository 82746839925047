import { createContext } from "react";

import { SettingsContextProps } from "../data/types";

export const SettingsContext = createContext<SettingsContextProps>({
  origins: [],
  salesLostReasons: [],
  notesTypes: [],
  autoPricingType: "SIMPLE",
  autoPricingCalc: "MARKUP",
  autoPricing: [],
  autoPricingDetailed: { pricingComponents: [] },
  autoFinancing: { banks: [] },
  preferences: {} as SettingsContextProps["preferences"],
  permissions: {} as SettingsContextProps["permissions"],
  individualPermissions: [] as SettingsContextProps["individualPermissions"],
  proposalSlug: "",
  updateAutoPricingTypeHandler: (newType) => {},
  updateAutoPricingCalcHandler: (newCalc) => {},
  updateOriginsHandler: (newOrigins) => {},
  updateSalesLostReasonsHandler: (newReasons) => {},
  updateNotesTypesHandler: (newTypes) => {},
  updateAutoPricingHandler: (newAutoPricing) => {},
  updateAutoPricingDetailedHandler: (newAutoPricingDetailed) => {},
  updateAutoFinancingHandler: (newAutoFinancing) => {},
  updatePreferencesHandler: (newPreferences) => {},
  updatePermissionsHandler: (newPermissions) => {},
  updateIndividualPermissionsHandler: (newPermissions) => {},
  updateProposalSlugHandler: (newSlug) => {},
});
