import React, { useState, useEffect, useContext } from "react";
import Fab from "@material-ui/core/Fab";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Zoom from "@material-ui/core/Zoom";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import Button from "@material-ui/core/Button";
import SendRoundedIcon from "@material-ui/icons/SendRounded";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";

import { AuthContext } from "../../../shared/context/authContext";
import { InputWhatsAppBtn } from "../FormElements/InputWhatsAppBtn";
import { useForm } from "../../hooks/formHook";
import { mobileCheck } from "../../util/mobileCheck";
import { VALIDATOR_REQUIRE } from "../../util/validators";

import { MainNavigationStyles } from "../Navigation/MainNavigationStyles";
import "./ButtonWhatsAppStyles.css";

interface ButtonWhatsAppProps {
  sales?: boolean;
  zoom?: boolean;
}

export const ButtonWhatsApp = (props: ButtonWhatsAppProps) => {
  const { sales, zoom } = props;
  const classes = MainNavigationStyles();
  const auth = useContext(AuthContext);
  const [showWindow, setShowWindow] = useState(false);
  const [link, setLink] = useState<string>("");
  const { formState, inputHandler } = useForm(
    {
      message: {
        value: !auth.isLoggedIn
          ? "Quero saber mais sobre o Azume!"
          : "Quero reportar um problema.",
        isValid: true,
      },
    },
    true
  );

  const trigger = useScrollTrigger({
    target: window,
    disableHysteresis: true,
    threshold: 100,
  });

  const showWindowToggleHandler = () => {
    setShowWindow((prevValue) => {
      return !prevValue;
    });
  };

  const getLink = () => {
    let apilink = "http://";
    const isMobile = mobileCheck();
    const message = formState.inputs.message.value.toString();
    const phone = "5531992330282";
    apilink += isMobile ? "api" : "web";
    apilink +=
      ".whatsapp.com/send?phone=" + phone + "&text=" + encodeURI(message);
    return apilink;
  };

  useEffect(() => {
    if (formState?.inputs?.message?.value) {
      setLink(getLink());
    }
  }, [auth.isLoggedIn, formState?.inputs?.message?.value]);

  return (
    <React.Fragment>
      <Zoom in={showWindow}>
        <div className="whatsapp-btn-window">
          <Paper style={{ boxShadow: "none" }}>
            <div className="whatsapp-btn-window-header">
              {auth.isLoggedIn && (
                <Typography
                  style={{
                    color: "#fff",
                    fontSize: "0.8rem",
                    width: "100%",
                  }}
                  variant="subtitle1"
                >
                  Azume - Suporte WhatsApp
                </Typography>
              )}
              {!auth.isLoggedIn && (
                <Typography
                  style={{
                    color: "#fff",
                    fontSize: "0.8rem",
                    width: "100%",
                  }}
                  variant="subtitle1"
                >
                  Azume - Deixe sua Dúvida
                </Typography>
              )}
              <CloseRoundedIcon
                onClick={showWindowToggleHandler}
                style={{ color: "#fff", cursor: "pointer" }}
              />
            </div>
            <div className="whatsapp-btn-window-message-body">
              <Paper style={{ padding: "0.3rem 0.5rem" }}>
                <Typography
                  style={{
                    color: "#707070",
                    fontSize: "0.8rem",
                    width: "100%",
                  }}
                  variant="subtitle1"
                >
                  Olá, tudo bem? Como podemos ajudar?
                </Typography>
              </Paper>
            </div>
            <div className="whatsapp-btn-window-message-form-container">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
                className="whatsapp-btn-window-message-form"
              >
                <InputWhatsAppBtn
                  id="message"
                  type="text"
                  label="mensagem"
                  variant="outlined"
                  initialValue={
                    !auth.isLoggedIn
                      ? "Quero saber mais sobre o Azume!"
                      : "Quero reportar um problema."
                  }
                  helperText={
                    !auth.isLoggedIn
                      ? "O que você quer saber sobre o Azume?"
                      : "Conte-nos sobre o problema que encontrou..."
                  }
                  initialValid={true}
                  multiline
                  rows={3}
                  onInput={inputHandler}
                  validators={[VALIDATOR_REQUIRE()]}
                />
                <Button
                  href={link}
                  target="_blank"
                  className="zap"
                  disabled={!formState.isValid}
                  color="primary"
                  style={{ margin: "auto 0", color: "#007bed" }}
                  title="Enviar mensagem."
                >
                  <SendRoundedIcon />
                </Button>
              </form>
            </div>
          </Paper>
        </div>
      </Zoom>
      <div className="whatsapp-btn">
        {zoom && (
          <Zoom in={trigger}>
            <div role="presentation" className={classes.scrollRootLeft}>
              <Fab color="primary" onClick={showWindowToggleHandler}>
                <WhatsAppIcon style={{ fontSize: "2.2rem" }} />
              </Fab>
            </div>
          </Zoom>
        )}
        {!zoom && (
          <Fab
            color="primary"
            className={classes.scrollRootLeft}
            onClick={showWindowToggleHandler}
          >
            <WhatsAppIcon style={{ fontSize: "2.2rem" }} />
          </Fab>
        )}
      </div>
    </React.Fragment>
  );
};
