import React, { useEffect, useState } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

interface InputSelectProps {
  id: string;
  label: string;
  onInput: (id: string, value: string, isValid: boolean, label: string) => void;
  options: string[];
  initialValue?: string;
  firstOption?: boolean;
  variant?: "standard" | "filled" | "outlined";
  disabled?: boolean;
}

export const InputSelect = (props: InputSelectProps) => {
  const {
    id,
    label,
    onInput,
    options,
    initialValue,
    variant,
    disabled,
    firstOption,
  } = props;
  const [inputValue, setInputValue] = useState(
    firstOption ? options[0] : initialValue || ""
  );
  const firstOptionValue = options[0];

  useEffect(() => {
    if (firstOption) {
      setInputValue(firstOptionValue);
    }
  }, [firstOptionValue, firstOption]);

  useEffect(() => {
    onInput(
      id,
      inputValue,
      true,
      label.charAt(0).toUpperCase() + label.slice(1)
    );
  }, [id, inputValue, label, onInput]);

  return (
    <FormControl variant={variant} disabled={disabled}>
      <InputLabel
        id={`input-label-${id}`}
        style={
          variant === "outlined"
            ? {
                backgroundColor: "#fff",
                paddingLeft: "5px",
                paddingRight: "5px",
                marginLeft: "-5px",
              }
            : {}
        }
      >
        {label.charAt(0).toUpperCase() + label.slice(1)}
      </InputLabel>
      <Select
        labelId={`label-${id}`}
        id={id}
        value={inputValue}
        onChange={(
          event: React.ChangeEvent<{ name?: string; value: string }>
        ) => {
          setInputValue(event.target.value);
        }}
      >
        {options.map((option) => {
          return (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
