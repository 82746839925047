import { Fragment, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import MoreHorizRoundedIcon from "@material-ui/icons/MoreHorizRounded";
import AttachMoneyRoundedIcon from "@material-ui/icons/AttachMoneyRounded";
import MoneyOffRoundedIcon from "@material-ui/icons/MoneyOffRounded";
import DoneOutlineRoundedIcon from "@material-ui/icons/DoneOutlineRounded";

import { ModalConfirm } from "../../shared/components/UIElements/ModalConfirm";
import { MenuSalesItemOptions } from "./MenuSalesItemOptions";
import { PopoverFormClosedSalePropChoice } from "./PopoverFormClosedSalePropChoice";
import { PopoverFormLostSaleReasonChoice } from "./PopoverFormLostSaleReasonChoice";
import { openWhatsapp } from "../../shared/util/openWhatsapp";
import { editSaleStatus } from "../api/salesFunnelAPI";
import { getSaleStatusNameFromId } from "../util/getSaleStatusNameFromId";
import { addThousandSeparator } from "../../shared/util/addThousandSeparator";
import { validDateChecker } from "../../shared/util/validDateChecker";
import {
  AuthContextProps,
  KanbanFilledData,
  SettingsContextProps,
} from "../../shared/data/types";

import "./SaleDash.scss";

interface SaleDashGenDataProps {
  memory: KanbanFilledData["items"][0];
  auth: AuthContextProps;
  settingsCtx: SettingsContextProps;
  kanbanData: KanbanFilledData;
  setLoadedData: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
  fetchId: string;
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean,
    zeroResultsKey?: string
  ) => Promise<any>;
  type: "SALES" | "PROJECT" | "INSTALLATION" | "AFTER_SALES";
  setActiveItem: React.Dispatch<React.SetStateAction<string>>;
  setUpdateColumnsTotals?: React.Dispatch<React.SetStateAction<string[]>>;
}

export const SaleDashGenData = (props: SaleDashGenDataProps) => {
  const {
    memory,
    auth,
    setLoadedData,
    sendRequest,
    setActiveItem,
    fetchId,
    kanbanData,
    type,
    settingsCtx,
    setUpdateColumnsTotals,
  } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>(null);
  const [anchorElFormCloseSale, setAnchorElFormCloseSale] = useState<
    null | HTMLButtonElement | HTMLLIElement
  >(null);
  const [anchorElFormLostSale, setAnchorElFormLostSale] = useState<
    null | HTMLButtonElement | HTMLLIElement
  >(null);
  const itemOwner = auth.vendors.find((vendor) => {
    return vendor?._id === memory?.user?.toString();
  });
  const [showConfirmFinishModal, setShowConfirmFinishModal] =
    useState<boolean>(false);
  const custProps = memory?.customer?.proposals;
  const totalViews =
    custProps?.length > 0
      ? custProps
          ?.map((prop) => {
            return prop?.views || 0;
          })
          ?.reduce((a, b) => {
            return a + b;
          })
      : 0;
  const saleMultOwners = memory?.owners || [];
  const saleMultOwnersNames = saleMultOwners
    ?.map((data) => {
      if (data === auth.managerUserId) {
        return "GERENTE";
      } else {
        return auth.vendors?.find((v) => {
          return v._id === data;
        })?.personalData?.name;
      }
    })
    ?.join(" | ");

  const finishTaskHandler = () => {
    setShowConfirmFinishModal(false);
    editSaleStatus({
      sendRequest,
      auth,
      status: "CLOSED",
      approvedProposal: "",
      itemId: memory?._id,
      setKanbanData: setLoadedData,
      toBeRemovedItem: memory,
    }).then(() => {
      if (setActiveItem) {
        setActiveItem(null);
      }
      if (setUpdateColumnsTotals) {
        setUpdateColumnsTotals([memory?.kanbanData?.column, "0"]);
      }
    });
    setAnchorEl(null);
  };

  return (
    <div className="sale-dash__cust-data">
      <ModalConfirm
        open={showConfirmFinishModal}
        closeHandler={() => {
          setShowConfirmFinishModal(false);
        }}
        confirmHandler={() => {
          finishTaskHandler();
        }}
        message="Deseja marcar este item como finalizado?"
      />
      <MenuSalesItemOptions
        item={memory}
        customerId={memory?.customer?.id}
        columnId={memory?.kanbanData?.column}
        anchorEl={anchorEl}
        settingsCtx={settingsCtx}
        setAnchorEl={setAnchorEl}
        id={memory?._id}
        sendRequest={sendRequest}
        auth={auth}
        setKanbanData={setLoadedData}
        fetchId={fetchId}
        kanbanData={kanbanData}
        setActiveItem={setActiveItem}
        type={type}
        setUpdateColumnsTotals={setUpdateColumnsTotals}
      />
      <PopoverFormClosedSalePropChoice
        itemId={memory?._id}
        item={memory}
        anchorEl={anchorElFormCloseSale}
        settingsCtx={settingsCtx}
        setAnchorEl={setAnchorElFormCloseSale}
        auth={auth}
        sendRequest={sendRequest}
        setKanbanData={setLoadedData}
        setActiveItem={setActiveItem}
        type={type}
        setUpdateColumnsTotals={setUpdateColumnsTotals}
      />
      <PopoverFormLostSaleReasonChoice
        itemId={memory?._id}
        item={memory}
        anchorEl={anchorElFormLostSale}
        setAnchorEl={setAnchorElFormLostSale}
        auth={auth}
        sendRequest={sendRequest}
        setKanbanData={setLoadedData}
        setActiveItem={setActiveItem}
        setUpdateColumnsTotals={setUpdateColumnsTotals}
      />
      <div className="sale-dash__title-container">
        <p className="sale-dash__title">{memory?.name}</p>
        <div
          className={`sale-dash__cust-data-actions ${
            type === "AFTER_SALES" ? "sale-dash__cust-data-actions--2" : ""
          }`}
        >
          {type === "SALES" && (
            <IconButton
              size="small"
              style={{
                color: memory?.status === "IN_PROGRESS" ? "#007bed" : "#aaa",
              }}
              title="Dar Ganho"
              className={`table__item-action`}
              disabled={memory?.status !== "IN_PROGRESS"}
              onClick={(e) => {
                setAnchorElFormCloseSale(e.currentTarget);
              }}
            >
              <AttachMoneyRoundedIcon style={{ fontSize: "22px" }} />
            </IconButton>
          )}
          {type === "SALES" && (
            <IconButton
              size="small"
              style={{
                color: memory?.status === "IN_PROGRESS" ? "#f50057" : "#aaa",
              }}
              title="Dar Perdido"
              className={`table__item-action`}
              disabled={memory?.status !== "IN_PROGRESS"}
              onClick={(e) => {
                setAnchorElFormLostSale(e.currentTarget);
              }}
            >
              <MoneyOffRoundedIcon style={{ fontSize: "22px" }} />
            </IconButton>
          )}
          {type === "AFTER_SALES" && (
            <IconButton
              size="small"
              style={{
                color: memory?.status === "IN_PROGRESS" ? "#007bed" : "#aaa",
              }}
              title="Finalizar"
              className={`table__item-action`}
              disabled={memory?.status !== "IN_PROGRESS"}
              onClick={(e) => {
                setShowConfirmFinishModal(true);
              }}
            >
              <DoneOutlineRoundedIcon style={{ fontSize: "22px" }} />
            </IconButton>
          )}
          <IconButton
            size="small"
            style={{ color: "#263351" }}
            title="Opções"
            className={`table__item-action`}
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
            }}
          >
            <MoreHorizRoundedIcon style={{ fontSize: "24px" }} />
          </IconButton>
        </div>
      </div>
      <div className="sale-dash__cust-data-item">
        {memory?.status !== "IN_PROGRESS" && (
          <Fragment>
            <p className="sale-dash__cust-data-item-label">Status:</p>
            <p
              className={`sale-dash__cust-data-item-content ${
                memory?.status === "CLOSED"
                  ? "sale-dash__cust-data-item-content--blue"
                  : "sale-dash__cust-data-item-content--red"
              }`}
            >
              {getSaleStatusNameFromId(memory?.status || "IN_PROGRESS")}
            </p>
          </Fragment>
        )}
        <p className="sale-dash__cust-data-item-label">Valor:</p>
        <p className="sale-dash__cust-data-item-content">
          {`R$ ${addThousandSeparator(memory?.amount) || "-"}`}{" "}
        </p>
        {type === "AFTER_SALES" && (
          <Fragment>
            <p className="sale-dash__cust-data-item-label">Prazo</p>
            <p
              className={`sale-dash__cust-data-item-content ${
                !validDateChecker(memory?.validityDate) &&
                !!memory?.validityDate
                  ? "sale-dash__cust-data-item-content--red"
                  : !!memory?.validityDate
                  ? "sale-dash__cust-data-item-content--blue"
                  : ""
              }`}
            >
              {memory?.validityDate?.substring(0, 10) || "-"}
            </p>
          </Fragment>
        )}
        <p className="sale-dash__cust-data-item-label">Cliente:</p>
        <p className="sale-dash__cust-data-item-content">
          {memory?.customer?.name}
        </p>
        <p className="sale-dash__cust-data-item-label">E-mail</p>
        <p className="sale-dash__cust-data-item-content">
          {memory?.customer?.email || "-"}
        </p>
        <p className="sale-dash__cust-data-item-label">Telefone</p>
        <p className="sale-dash__cust-data-item-content">
          {memory?.customer?.whatsapp && (
            <span
              onClick={() => {
                openWhatsapp(memory?.customer?.whatsapp?.replace(/\D/g, ""));
              }}
              className="sale-dash__cust-data-item-subcontent sale-dash__cust-data-item-subcontent--clickable"
            >
              {memory?.customer?.whatsapp}
            </span>
          )}
          {memory?.customer?.phone && (
            <span className="sale-dash__cust-data-item-subcontent">
              {memory?.customer?.phone}
            </span>
          )}
          {!memory?.customer?.phone && !memory?.customer?.whatsapp && (
            <span className="sale-dash__cust-data-item-subcontent">-</span>
          )}
        </p>
        <p className="sale-dash__cust-data-item-label">
          {memory?.customer?.document?.replace(/\D/g, "")?.length === 14
            ? "CNPJ"
            : "CPF"}
        </p>
        <p className="sale-dash__cust-data-item-content">
          {memory?.customer?.document || "-"}
        </p>
        <p className="sale-dash__cust-data-item-label">Cidade</p>
        <p className="sale-dash__cust-data-item-content">
          {memory?.customer?.city || "-"}
        </p>
        <p className="sale-dash__cust-data-item-label">Endereço</p>
        <p className="sale-dash__cust-data-item-content">
          {memory?.customer?.address || "-"}
        </p>
        {type === "SALES" && (
          <Fragment>
            <p className="sale-dash__cust-data-item-label">Origem</p>
            <p className="sale-dash__cust-data-item-content">
              {memory?.customer?.origin || "-"}
            </p>
          </Fragment>
        )}
        {type === "SALES" && (
          <Fragment>
            <p className="sale-dash__cust-data-item-label">Propostas</p>
            <p className="sale-dash__cust-data-item-content">
              {memory?.customer?.proposals?.length || "0"}
            </p>
          </Fragment>
        )}
        {type === "SALES" && (
          <Fragment>
            <p className="sale-dash__cust-data-item-label">Visualizações</p>
            <p className="sale-dash__cust-data-item-content">
              {totalViews || "0"}
            </p>
          </Fragment>
        )}
        <p className="sale-dash__cust-data-item-label">Notas</p>
        <p className="sale-dash__cust-data-item-content">
          {memory?.notes?.length || "0"}
        </p>
        <p className="sale-dash__cust-data-item-label">Proprietários</p>
        {type === "SALES" && (
          <p className="sale-dash__cust-data-item-content">
            {itemOwner?.personalData?.name || "GERENTE"}
          </p>
        )}
        {type === "AFTER_SALES" && (
          <p className="sale-dash__cust-data-item-content">
            {saleMultOwnersNames || "GERENTE"}
          </p>
        )}
        {type === "SALES" && (
          <Fragment>
            <p className="sale-dash__cust-data-item-label">Validade</p>
            <p className="sale-dash__cust-data-item-content">
              {memory?.validityDate || "-"}
            </p>
          </Fragment>
        )}
        <p className="sale-dash__cust-data-item-label">Registro</p>
        <p className="sale-dash__cust-data-item-content">
          {memory?.registryDate || "-"}
        </p>
        <p className="sale-dash__cust-data-item-label">ID</p>
        <p className="sale-dash__cust-data-item-content">{memory?.id}</p>
      </div>
    </div>
  );
};
