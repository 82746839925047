import { Fragment } from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import "./ButtonLoadMoreItemsStyles.css";

interface ButtonLoadMoreProps {
  setMultiplier: React.Dispatch<React.SetStateAction<number>>;
  showLoadMore: boolean;
  isLoading: boolean;
  fetchItems: (multiplier: number) => void;
  small?: boolean;
  noMargin?: boolean;
}

export const ButtonLoadMore = (props: ButtonLoadMoreProps) => {
  const {
    setMultiplier,
    showLoadMore,
    isLoading,
    fetchItems,
    small,
    noMargin,
  } = props;

  return (
    <Fragment>
      {showLoadMore && (
        <div
          style={noMargin ? { margin: "15px 0 0" } : {}}
          className={`button-load-more-items-root ${
            small ? "button-load-more-items-root--small" : ""
          }`}
        >
          {!isLoading && (
            <Button
              onClick={() => {
                setMultiplier((prevValues) => {
                  fetchItems(prevValues + 1);
                  return (prevValues += 1);
                });
              }}
              variant="outlined"
              color="primary"
              size={!small ? "large" : "small"}
            >
              Carregar Mais
            </Button>
          )}
          {isLoading && <CircularProgress color="primary" />}
        </div>
      )}
    </Fragment>
  );
};
