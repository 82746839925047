import { useEffect, useState } from "react";
import { Chart, PieSeries } from "@devexpress/dx-react-chart-material-ui";
import { Animation } from "@devexpress/dx-react-chart";
import { Palette } from "@devexpress/dx-react-chart";

import { useHttpClient } from "../../../shared/hooks/httpHook";
import { ModalError } from "../../../shared/components/UIElements/ModalError";
import { LoadingSpinnerOverlayRegular } from "../../../shared/components/UIElements/LoadingSpinnerOverlayRegular";
import { addThousandSeparator } from "../../../shared/util/addThousandSeparator";
import { generateFormattedDateFromTimestamp } from "../../../shared/util/generateFormattedDateFromTimestamp";
import { getMonthNameAndYear } from "../../../shared/util/getMonthNameAndYear";
import {
  getKanban,
  populateKanbanItem,
} from "../../../sales/api/salesFunnelAPI";
import {
  AuthContextProps,
  SettingsContextProps,
  SalePopCustData,
  KanbanFilledData,
  SettingsContextData,
  NotePopCustData,
  SaleData,
  TimePeriodData,
} from "../../../shared/data/types";

import "./HomeDashboard.scss";

interface HomeDashboardNextSchedulesItemProps {
  item: NotePopCustData;
  auth: AuthContextProps;
  fetchId: string;
  kanbanData: KanbanFilledData;
  settingsCtx: SettingsContextData;
  type: "SALES" | "PROJECT" | "INSTALLATION" | "AFTER_SALES";
  setType: React.Dispatch<
    React.SetStateAction<"SALES" | "PROJECT" | "INSTALLATION" | "AFTER_SALES">
  >;
  setLoadedData: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
  setActiveItem: React.Dispatch<React.SetStateAction<string>>;
  setActiveNote: React.Dispatch<React.SetStateAction<string>>;
}

export const HomeDashboardNextSchedulesItem = (
  props: HomeDashboardNextSchedulesItemProps
) => {
  const {
    item,
    auth,
    fetchId,
    kanbanData,
    settingsCtx,
    setLoadedData,
    setActiveItem,
    setActiveNote,
    setType,
    type,
  } = props;
  const { error, sendRequest, clearError, isLoading } = useHttpClient();
  const itemSale = item.sale as SaleData;
  const itemTypeTxt =
    itemSale.type === "SALES"
      ? "VENDA"
      : itemSale.type === "PROJECT"
      ? "PROJETO"
      : itemSale.type === "INSTALLATION"
      ? "OBRA"
      : "PÓS-VEN.";

  const handlePopulateAndActivateItem = () => {
    const noteSale = item.sale as SaleData;

    if (!kanbanData || noteSale.type !== type) {
      getKanban({
        sendRequest,
        auth,
        fetchId: auth.type === "MANAGER" ? auth.managerId : auth?.userId,
        type: noteSale.type,
        searchValue: "",
        filter1Active: false,
        filter1Value: {} as TimePeriodData,
        setKanbanData: setLoadedData,
        hidration: true,
        multiplier: 0,
        kanbanData,
      }).then(() => {
        populateKanbanItem({
          sendRequest,
          auth,
          setKanbanData: setLoadedData,
          itemId: noteSale?._id,
          type,
        }).then(() => {
          setActiveNote(item._id);
          setType(noteSale.type);
          setActiveItem(noteSale._id as string);
        });
      });
    } else {
      const kbNoteSale = kanbanData?.items?.find((item) => {
        return item._id === noteSale._id;
      });

      if (!kbNoteSale?.customer?.user || !kbNoteSale?.registryDate) {
        populateKanbanItem({
          sendRequest,
          auth,
          setKanbanData: setLoadedData,
          itemId: noteSale?._id,
          type,
        }).then(() => {
          setActiveNote(item._id);
          setType(noteSale.type);
          setActiveItem(noteSale._id as string);
        });
      } else {
        setActiveNote(item._id);
        setType(noteSale.type);
        setActiveItem(noteSale._id as string);
      }
    }
  };

  return (
    <div className="dashboard-item__list-item-container">
      {isLoading && <LoadingSpinnerOverlayRegular size={25} />}
      <div
        onClick={handlePopulateAndActivateItem}
        className="dashboard-item__list-item dashboard-item__list-item--4-col"
      >
        <p className="dashboard-item__list-item-name">{item.customer?.name}</p>
        <p className="dashboard-item__list-item-amount">
          {item.type || "Sem Tipo"}
        </p>
        <span
          className={`dashboard-item__list-item-status ${
            itemSale.type === "SALES"
              ? "dashboard-item__list-item-status--neutral"
              : itemSale.type === "PROJECT"
              ? "dashboard-item__list-item-status--pos"
              : itemSale.type === "INSTALLATION"
              ? "dashboard-item__list-item-status--pos-2"
              : "dashboard-item__list-item-status--pos-3"
          }`}
        >
          {itemTypeTxt}
        </span>
        <p className="dashboard-item__list-item-date">
          {generateFormattedDateFromTimestamp(item.notificationTimestamp)}
        </p>
      </div>
    </div>
  );
};
