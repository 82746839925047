import React, { useEffect, useState, FocusEvent } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

interface InputSelectRequiredProps {
  id: string;
  label: string;
  onInput: (id: string, value: string, isValid: boolean, label: string) => void;
  options: string[];
  initialValue?: string;
  variant?: "standard" | "filled" | "outlined";
  disabled?: boolean;
  forceError?: boolean;
  trackFocus?: boolean;
  focusHandler?: (
    event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  reset?: boolean;
  resetOnOptionsChange?: boolean;
  updateInitValue?: boolean;
}

export const InputSelectRequired = (props: InputSelectRequiredProps) => {
  const {
    id,
    label,
    onInput,
    options,
    initialValue,
    forceError,
    variant,
    disabled,
    trackFocus,
    focusHandler,
    resetOnOptionsChange,
    reset,
    updateInitValue,
  } = props;
  const [inputValue, setInputValue] = useState(initialValue || "");
  const [isTouched, setIsTouched] = useState(false);

  useEffect(() => {
    onInput(
      id,
      inputValue,
      !!inputValue,
      label.charAt(0).toUpperCase() + label.slice(1)
    );
  }, [id, inputValue, label, onInput]);

  useEffect(() => {
    if (reset) {
      onInput(id, "", false, label.charAt(0).toUpperCase() + label.slice(1));
      setInputValue("");
      setIsTouched(false);
    }
  }, [reset, id, inputValue, label, onInput]);

  useEffect(() => {
    if (updateInitValue) {
      setInputValue(initialValue);
    }
  }, [initialValue, updateInitValue]);

  useEffect(() => {
    if (resetOnOptionsChange && !options?.includes(inputValue)) {
      setInputValue(initialValue);
      onInput(
        id,
        initialValue,
        !!initialValue,
        label.charAt(0).toUpperCase() + label.slice(1)
      );
    }
  }, [options, resetOnOptionsChange]);

  const touchHandler = () => {
    setIsTouched(true);
  };

  return (
    <FormControl variant={variant} disabled={disabled}>
      <InputLabel
        variant={variant}
        error={(!inputValue && isTouched) || forceError}
        style={
          variant === "outlined"
            ? {
                backgroundColor: "#fff",
                paddingLeft: "5px",
                paddingRight: "5px",
                marginLeft: "-5px",
              }
            : {}
        }
        id={`input-label-${id}`}
      >
        {label.charAt(0).toUpperCase() + label.slice(1)}
      </InputLabel>
      <Select
        variant={variant}
        labelId={`label-${id}`}
        error={(!inputValue && isTouched) || forceError}
        id={id}
        value={inputValue}
        onBlur={touchHandler}
        onFocus={trackFocus && focusHandler}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setInputValue(event.target.value);
        }}
      >
        {options.map((option) => {
          return (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
