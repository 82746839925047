import { Fragment, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import Collapse from "@material-ui/core/Collapse";
import PersonRoundedIcon from "@material-ui/icons/PersonRounded";
import FlashOnRoundedIcon from "@material-ui/icons/FlashOnRounded";

import { useHttpClient } from "../../shared/hooks/httpHook";
import { LoadingSpinnerOverlayRegular } from "../../shared/components/UIElements/LoadingSpinnerOverlayRegular";
import { addThousandSeparator } from "../../shared/util/addThousandSeparator";
import { ProposalData, AuthContextProps } from "../../shared/data/types";

import "./PropDashUcItem.scss";

interface PropDashUcItemProps {
  auth: AuthContextProps;
  index: number;
  loadedUc: ProposalData["energyBillData"][0];
  isAGroup: boolean;
  losses: number;
  memory: ProposalData;
}

export const PropDashUcItem = (props: PropDashUcItemProps) => {
  const { loadedUc, auth, index, isAGroup, losses, memory } = props;
  const { sendRequest, isLoading } = useHttpClient();
  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>(null);
  const avgMonthlyConsp =
    loadedUc.monthlyConsumption?.length >= 1
      ? loadedUc.monthlyConsumption?.reduce((a, b) => {
          return a + b;
        }) / 12
      : 0;
  const avgMonthlyConspPeak =
    loadedUc.monthlyConsumptionPeak?.length >= 1
      ? loadedUc.monthlyConsumptionPeak?.reduce((a, b) => {
          return a + b;
        }) / 12
      : 0;

  const perc = !isAGroup
    ? loadedUc?.monthlyConsumption.reduce((a, b) => {
        return a + b;
      }) / memory?.estimateSystemData?.averageYearlyConsumption
    : (loadedUc?.monthlyConsumption.reduce((a, b) => {
        return a + b;
      }) +
        loadedUc?.monthlyConsumptionPeak.reduce((a, b) => {
          return a + b;
        })) /
      (memory?.estimateSystemData?.averageYearlyConsumption +
        memory?.estimateSystemData?.averageYearlyConsumptionPeak);

  const energyPerc = perc * memory?.realSystemData?.averageMontlhyGeneration;

  return (
    <Fragment>
      <div
        className="cust-prop-item-prop"
        onClick={(e) => {
          if (!anchorEl) {
            setOpen(!open);
          }
        }}
      >
        {isLoading && <LoadingSpinnerOverlayRegular image color="primary" />}
        <div className="cust-prop-item-prop__data">
          <p className="cust-prop-item-prop__data-item cust-prop-item-prop__data-item--thin">
            {loadedUc?.ucName || `Unidade Consumidora ${index + 1}`}
          </p>
          <div className="cust-prop-item-prop__user-data">
            <FlashOnRoundedIcon />
            <div className="cust-prop-item-prop__user-data-content">
              <p className="cust-prop-item-prop__data-item">
                {isAGroup ? "Grupo A" : "Grupo B"}
              </p>
              <p className="cust-prop-item-prop__data-item">
                {loadedUc.ucType || "Não Definido"}
              </p>
            </div>
          </div>
        </div>
        <div className="cust-prop-item-prop__controls">
          <IconButton
            size="small"
            title={open ? "Ver Mais" : "Fechar"}
            className={`table__item-action${open ? "--open" : ""}`}
            onClick={(e) => {
              setOpen(!open);
              e.stopPropagation();
            }}
          >
            <ExpandMoreRoundedIcon style={{ fontSize: "22px" }} />
          </IconButton>
        </div>
        <div className="cust-prop-item-prop__details">
          <Collapse in={open}>
            <div className="cust-prop-item-prop__details-content">
              <p className="cust-prop-item-prop__data-item cust-prop-item-prop__data-item--thin">
                Concessionária:{" "}
                <span className="cust-prop-item-prop__data-item--highlight">
                  {loadedUc?.finViabilityData?.powerDistCompany || "-"}
                </span>
              </p>
              {isAGroup && (
                <p className="cust-prop-item-prop__data-item cust-prop-item-prop__data-item--thin">
                  Classificação Horo-Sazonal:{" "}
                  <span className="cust-prop-item-prop__data-item--highlight">
                    {loadedUc?.classification}
                  </span>
                </p>
              )}
              {!isAGroup && (
                <p className="cust-prop-item-prop__data-item cust-prop-item-prop__data-item--thin">
                  Consumo:{" "}
                  <span className="cust-prop-item-prop__data-item--highlight">
                    {addThousandSeparator(avgMonthlyConsp)} kWh - R${" "}
                    {addThousandSeparator(
                      loadedUc?.kwhPrice * avgMonthlyConsp || 0
                    )}{" "}
                  </span>
                </p>
              )}
              {isAGroup && (
                <p className="cust-prop-item-prop__data-item cust-prop-item-prop__data-item--thin">
                  Consumo Fora Ponta:{" "}
                  <span className="cust-prop-item-prop__data-item--highlight">
                    {addThousandSeparator(avgMonthlyConsp)} kWh - R${" "}
                    {addThousandSeparator(
                      (loadedUc?.kwhPrice + loadedUc?.tusd) * avgMonthlyConsp ||
                        0
                    )}{" "}
                  </span>
                </p>
              )}
              {isAGroup && (
                <p className="cust-prop-item-prop__data-item cust-prop-item-prop__data-item--thin">
                  Consumo Ponta:{" "}
                  <span className="cust-prop-item-prop__data-item--highlight">
                    {addThousandSeparator(avgMonthlyConspPeak)} kWh - R${" "}
                    {addThousandSeparator(
                      (loadedUc?.kwhPricePeak + loadedUc?.tusdPeak) *
                        avgMonthlyConspPeak || 0
                    )}
                  </span>
                </p>
              )}
              <p className="cust-prop-item-prop__data-item cust-prop-item-prop__data-item--thin">
                Distribuição da Geração:{" "}
                <span className="cust-prop-item-prop__data-item--highlight">
                  {addThousandSeparator(energyPerc)} kWh (
                  {addThousandSeparator(perc * 100)} %)
                </span>{" "}
              </p>
              {isAGroup && loadedUc.classification === "Verde" && (
                <p className="cust-prop-item-prop__data-item cust-prop-item-prop__data-item--thin">
                  Demanda:{" "}
                  <span className="cust-prop-item-prop__data-item--highlight">
                    {addThousandSeparator(loadedUc?.demand || 0)} kW - R${" "}
                    {addThousandSeparator(
                      loadedUc?.demandTariff * loadedUc?.demand || 0
                    )}
                  </span>
                </p>
              )}
              {isAGroup && loadedUc.classification === "Azul" && (
                <p className="cust-prop-item-prop__data-item cust-prop-item-prop__data-item--thin">
                  Demanda Fora Ponta:{" "}
                  <span className="cust-prop-item-prop__data-item--highlight">
                    {addThousandSeparator(loadedUc?.demand || 0)} kW - R${" "}
                    {addThousandSeparator(
                      loadedUc?.demandTariff * loadedUc?.demand
                    )}
                  </span>
                </p>
              )}
              {isAGroup && loadedUc.classification === "Azul" && (
                <p className="cust-prop-item-prop__data-item cust-prop-item-prop__data-item--thin">
                  Demanda Ponta:{" "}
                  <span className="cust-prop-item-prop__data-item--highlight">
                    {addThousandSeparator(loadedUc?.demandPeak || 0)} kW - R${" "}
                    {addThousandSeparator(
                      loadedUc?.demandTariffPeak * loadedUc?.demandPeak || 0
                    )}
                  </span>
                </p>
              )}
              {isAGroup && loadedUc?.demandGen > 0 && (
                <p className="cust-prop-item-prop__data-item cust-prop-item-prop__data-item--thin">
                  Demanda Geração:{" "}
                  <span className="cust-prop-item-prop__data-item--highlight">
                    {addThousandSeparator(loadedUc.demandGen || 0)} kW - R${" "}
                    {addThousandSeparator(
                      loadedUc.demandGen * loadedUc?.demandTariffTusdG || 0
                    )}
                  </span>
                </p>
              )}
              <p className="cust-prop-item-prop__data-item cust-prop-item-prop__data-item--thin">
                Fator de Simultaneidade:{" "}
                <span className="cust-prop-item-prop__data-item--highlight">
                  {addThousandSeparator(
                    loadedUc?.finViabilityData?.simultaneityFactor
                  ) || "-"}
                  %
                </span>
              </p>
              {!isAGroup && (
                <p className="cust-prop-item-prop__data-item cust-prop-item-prop__data-item--thin">
                  Valor do Fio B:{" "}
                  <span className="cust-prop-item-prop__data-item--highlight">
                    R${" "}
                    {addThousandSeparator(
                      loadedUc?.finViabilityData?.bWireValue
                    ) || "-"}{" "}
                  </span>
                </p>
              )}
              <p className="cust-prop-item-prop__data-item cust-prop-item-prop__data-item--thin">
                Perdas:{" "}
                <span className="cust-prop-item-prop__data-item--highlight">
                  {addThousandSeparator(losses) || "-"} %
                </span>
              </p>
            </div>
          </Collapse>
        </div>
      </div>
    </Fragment>
  );
};
