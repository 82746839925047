import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

export const TextFieldSmallFontSize = withStyles({
  root: {
    "& label": {
      fontSize: "0.85rem",
    },
  },
})(TextField);
