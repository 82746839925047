import { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import MoreHorizRoundedIcon from "@material-ui/icons/MoreHorizRounded";
import DoneOutlineRoundedIcon from "@material-ui/icons/DoneOutlineRounded";

import { ModalConfirm } from "../../shared/components/UIElements/ModalConfirm";
import { MenuSalesItemOptions } from "./MenuSalesItemOptions";
import { openWhatsapp } from "../../shared/util/openWhatsapp";
import { editSaleStatus } from "../api/salesFunnelAPI";
import { validDateChecker } from "../../shared/util/validDateChecker";
import { addThousandSeparator } from "../../shared/util/addThousandSeparator";
import {
  AuthContextProps,
  KanbanFilledData,
  SettingsContextProps,
  ProposalData,
} from "../../shared/data/types";

import "./SaleDash.scss";

interface TaskDashGenDataProps {
  memory: KanbanFilledData["items"][0];
  auth: AuthContextProps;
  settingsCtx: SettingsContextProps;
  kanbanData: KanbanFilledData;
  setLoadedData: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
  fetchId: string;
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean,
    zeroResultsKey?: string
  ) => Promise<any>;
  type: "SALES" | "PROJECT" | "INSTALLATION" | "AFTER_SALES";
  setActiveItem: React.Dispatch<React.SetStateAction<string>>;
  setUpdateColumnsTotals?: React.Dispatch<React.SetStateAction<string[]>>;
}

export const TaskDashGenData = (props: TaskDashGenDataProps) => {
  const {
    memory,
    auth,
    setLoadedData,
    sendRequest,
    setActiveItem,
    fetchId,
    kanbanData,
    type,
    settingsCtx,
    setUpdateColumnsTotals,
  } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>(null);
  const [showConfirmFinishModal, setShowConfirmFinishModal] =
    useState<boolean>(false);
  const proposal = memory?.approvedProposal as ProposalData;
  const saleOwners = memory?.owners || [];
  const saleOwnersNames = saleOwners
    ?.map((data) => {
      if (data === auth.managerUserId) {
        return "GERENTE";
      } else {
        return auth.vendors?.find((v) => {
          return v._id === data;
        })?.personalData?.name;
      }
    })
    ?.join(" | ");

  const finishTaskHandler = () => {
    setShowConfirmFinishModal(false);
    editSaleStatus({
      sendRequest,
      auth,
      status: "CLOSED",
      approvedProposal: "",
      itemId: memory?._id,
      setKanbanData: setLoadedData,
      toBeRemovedItem: memory,
    }).then(() => {
      if (setActiveItem) {
        setActiveItem(null);
      }
      if (setUpdateColumnsTotals) {
        setUpdateColumnsTotals([memory?.kanbanData?.column, "0"]);
      }
    });
    setAnchorEl(null);
  };

  return (
    <div className="sale-dash__cust-data">
      <ModalConfirm
        open={showConfirmFinishModal}
        closeHandler={() => {
          setShowConfirmFinishModal(false);
        }}
        confirmHandler={() => {
          finishTaskHandler();
        }}
        message="Deseja marcar este item como finalizado?"
      />
      <MenuSalesItemOptions
        item={memory}
        customerId={memory?.customer?.id}
        columnId={memory?.kanbanData?.column}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        id={memory?._id}
        sendRequest={sendRequest}
        auth={auth}
        setKanbanData={setLoadedData}
        fetchId={fetchId}
        kanbanData={kanbanData}
        settingsCtx={settingsCtx}
        setActiveItem={setActiveItem}
        type={type}
        setUpdateColumnsTotals={setUpdateColumnsTotals}
      />
      <div className="sale-dash__title-container">
        <p className="sale-dash__title">{memory?.name}</p>
        <div className="sale-dash__cust-data-actions sale-dash__cust-data-actions--2">
          <IconButton
            size="small"
            style={{
              color: memory?.status === "IN_PROGRESS" ? "#007bed" : "#aaa",
            }}
            title="Finalizar"
            className={`table__item-action`}
            disabled={memory?.status !== "IN_PROGRESS"}
            onClick={(e) => {
              setShowConfirmFinishModal(true);
            }}
          >
            <DoneOutlineRoundedIcon style={{ fontSize: "22px" }} />
          </IconButton>
          <IconButton
            size="small"
            style={{ color: "#263351" }}
            title="Opções"
            className={`table__item-action`}
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
            }}
          >
            <MoreHorizRoundedIcon style={{ fontSize: "24px" }} />
          </IconButton>
        </div>
      </div>
      <div className="sale-dash__cust-data-item">
        <p className="sale-dash__cust-data-item-label">Número</p>
        <p className="sale-dash__cust-data-item-content">
          {proposal?.count >= 0 ? "#" : ""}
          {proposal?.count?.toLocaleString("pt-BR", {
            minimumIntegerDigits: 5,
            useGrouping: false,
          }) || "-"}
        </p>
        <p className="sale-dash__cust-data-item-label">Prazo</p>
        <p
          className={`sale-dash__cust-data-item-content ${
            !validDateChecker(memory?.validityDate) && !!memory?.validityDate
              ? "sale-dash__cust-data-item-content--red"
              : !!memory?.validityDate
              ? "sale-dash__cust-data-item-content--blue"
              : ""
          }`}
        >
          {memory?.validityDate?.substring(0, 10) || "-"}
        </p>
        <p className="sale-dash__cust-data-item-label">Cliente</p>
        <p className="sale-dash__cust-data-item-content">
          {proposal?.customerData?.name}
        </p>
        <p className="sale-dash__cust-data-item-label">E-mail</p>
        <p className="sale-dash__cust-data-item-content">
          {proposal?.customerData?.email || "-"}
        </p>
        <p className="sale-dash__cust-data-item-label">Telefone</p>
        <p className="sale-dash__cust-data-item-content">
          {proposal?.customerData?.whatsapp && (
            <span
              onClick={() => {
                openWhatsapp(
                  proposal?.customerData?.whatsapp?.replace(/\D/g, "")
                );
              }}
              className="sale-dash__cust-data-item-subcontent sale-dash__cust-data-item-subcontent--clickable"
            >
              {proposal?.customerData?.whatsapp}
            </span>
          )}
          {!proposal?.customerData?.whatsapp && (
            <span className="sale-dash__cust-data-item-subcontent">-</span>
          )}
        </p>
        <p className="sale-dash__cust-data-item-label">Potência</p>
        <p className="sale-dash__cust-data-item-content">
          {`${addThousandSeparator(
            proposal?.realSystemData?.power || 0
          )} kWp` || "-"}
        </p>
        <p className="sale-dash__cust-data-item-label">Cidade</p>
        <p className="sale-dash__cust-data-item-content">
          {proposal?.location?.city || "-"}
        </p>
        <p className="sale-dash__cust-data-item-label">Endereço</p>
        <p className="sale-dash__cust-data-item-content">
          {proposal?.location?.address || "-"}
        </p>
        <p className="sale-dash__cust-data-item-label">Proprietários</p>
        <p className="sale-dash__cust-data-item-content">
          <span className="sale-dash__cust-data-item-subcontent">
            {saleOwnersNames || "GERENTE"}
          </span>
        </p>
        <p className="sale-dash__cust-data-item-label">Registro</p>
        <p className="sale-dash__cust-data-item-content">
          {proposal?.registryDate || "-"}
        </p>
        <p className="sale-dash__cust-data-item-label">ID</p>
        <p className="sale-dash__cust-data-item-content">{proposal?.id}</p>
      </div>
    </div>
  );
};
