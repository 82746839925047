import { getMonthNameAndYear } from "../../shared/util/getMonthNameAndYear";
import {
  AuthContextProps,
  FormHookDispState,
  ContentData,
  SalePopCustData,
  KanbanData,
  KanbanFilledData,
  NotePopCustData,
} from "../../shared/data/types";

export interface FetchProps {
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean
  ) => Promise<any>;
  auth: AuthContextProps;
}

// GET CUSTOMERS AND PROPOSALS TOTALS ///////////////////////////////////
/////////////////////////////////////////////////////////////////////////
interface GetCustsAndPropsTotalsProps extends FetchProps {
  setLoadedData: React.Dispatch<
    React.SetStateAction<{ totalCustomers: number; totalProposals: number }>
  >;
  fetchId: string;
}

export const getCustsAndPropsTotals = async (
  props: GetCustsAndPropsTotalsProps
) => {
  const { sendRequest, setLoadedData, auth, fetchId } = props;

  const fetchType: "MANAGER" | "USER" =
    fetchId === auth.managerId ? "MANAGER" : "USER";

  try {
    const responseData: { totalCustomers: number; totalProposals: number } =
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/analytics/customers-and-proposals-totals/${fetchType}/${fetchId}`,
        "GET",
        null,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

    setLoadedData(responseData);
  } catch (err) {}
};

// GET GENERATED SALES //////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////
interface GetGenSalesProps extends FetchProps {
  setLoadedData: React.Dispatch<
    React.SetStateAction<
      {
        label: string;
        val: number;
        sales: number;
        delta: number;
        deltaIsPositive: boolean;
      }[]
    >
  >;
  fetchId: string;
}

export const getGenSales = async (props: GetGenSalesProps) => {
  const { sendRequest, setLoadedData, auth, fetchId } = props;

  const fetchType: "MANAGER" | "USER" =
    fetchId === auth.managerId ? "MANAGER" : "USER";

  try {
    const responseData: {
      totalAmount1: number;
      totalSales1: number;
      totalAmount2: number;
      totalSales2: number;
      totalAmount3: number;
      totalSales3: number;
    } = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/analytics/gen-sales/${fetchType}/${fetchId}`,
      "GET",
      null,
      {
        Authorization: "Bearer " + auth.token,
      }
    );

    const delta1 =
      responseData?.totalAmount1 / responseData?.totalAmount2 >= 1
        ? (responseData?.totalAmount1 / responseData?.totalAmount2 - 1) * 100
        : (1 - responseData?.totalAmount1 / responseData?.totalAmount2) * 100;

    const delta2 =
      responseData?.totalAmount2 / responseData?.totalAmount3 >= 1
        ? (responseData?.totalAmount2 / responseData?.totalAmount3 - 1) * 100
        : (1 - responseData?.totalAmount2 / responseData?.totalAmount3) * 100;

    setLoadedData([
      {
        label: getMonthNameAndYear(0),
        val: responseData?.totalAmount1 || 0,
        sales: responseData?.totalSales1 || 0,
        delta: delta1,
        deltaIsPositive:
          responseData?.totalAmount1 / responseData?.totalAmount2 >= 1,
      },
      {
        label: getMonthNameAndYear(-1),
        val: responseData?.totalAmount2 || 0,
        sales: responseData?.totalSales2 || 0,
        delta: delta2,
        deltaIsPositive:
          responseData?.totalAmount2 / responseData?.totalAmount3 >= 1,
      },
      {
        label: getMonthNameAndYear(-2),
        val: responseData?.totalAmount3 || 0,
        sales: responseData?.totalSales3 || 0,
        delta: 1,
        deltaIsPositive: true,
      },
      // {
      //   label: getMonthNameAndYear(-3),
      //   val: responseData?.totalAmount4 || 0,
      //   sales: responseData?.totalSales4 || 0,
      //   delta: 1,
      //   deltaIsPositive: true,
      // },
      // {
      //   label: getMonthNameAndYear(-4),
      //   val: responseData?.totalAmount5 || 0,
      //   sales: responseData?.totalSales5 || 0,
      //   delta: 1,
      //   deltaIsPositive: true,
      // },
      // {
      //   label: getMonthNameAndYear(-5),
      //   val: responseData?.totalAmount6 || 0,
      //   sales: responseData?.totalSales6 || 0,
      //   delta: 1,
      //   deltaIsPositive: true,
      // },
    ]);
  } catch (err) {}
};

// GET CLOASED SALES ////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////
interface GetClosedSalesProps extends FetchProps {
  setLoadedData: React.Dispatch<
    React.SetStateAction<
      {
        label: string;
        val: number;
        sales: number;
        power: number;
        delta: number;
        deltaIsPositive: boolean;
      }[]
    >
  >;
  fetchId: string;
}

export const getClosedSales = async (props: GetClosedSalesProps) => {
  const { sendRequest, setLoadedData, auth, fetchId } = props;

  const fetchType: "MANAGER" | "USER" =
    fetchId === auth.managerId ? "MANAGER" : "USER";

  try {
    const responseData: {
      totalAmount1: number;
      totalSales1: number;
      totalPower1: number;
      totalAmount2: number;
      totalSales2: number;
      totalPower2: number;
      totalAmount3: number;
      totalSales3: number;
      totalPower3: number;
    } = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/analytics/closed-sales/${fetchType}/${fetchId}`,
      "GET",
      null,
      {
        Authorization: "Bearer " + auth.token,
      }
    );

    const delta1 =
      responseData?.totalAmount1 / responseData?.totalAmount2 >= 1
        ? (responseData?.totalAmount1 / responseData?.totalAmount2 - 1) * 100
        : (1 - responseData?.totalAmount1 / responseData?.totalAmount2) * 100;

    const delta2 =
      responseData?.totalAmount2 / responseData?.totalAmount3 >= 1
        ? (responseData?.totalAmount2 / responseData?.totalAmount3 - 1) * 100
        : (1 - responseData?.totalAmount2 / responseData?.totalAmount3) * 100;

    setLoadedData([
      {
        label: getMonthNameAndYear(0),
        val: responseData?.totalAmount1 || 0,
        sales: responseData?.totalSales1 || 0,
        power: responseData?.totalPower1 || 0,
        delta: delta1,
        deltaIsPositive:
          responseData?.totalAmount1 / responseData?.totalAmount2 >= 1,
      },
      {
        label: getMonthNameAndYear(-1),
        val: responseData?.totalAmount2 || 0,
        sales: responseData?.totalSales2 || 0,
        power: responseData?.totalPower2 || 0,
        delta: delta2,
        deltaIsPositive:
          responseData?.totalAmount2 / responseData?.totalAmount3 >= 1,
      },
      {
        label: getMonthNameAndYear(-2),
        val: responseData?.totalAmount3 || 0,
        sales: responseData?.totalSales3 || 0,
        power: responseData?.totalPower3 || 0,
        delta: 1,
        deltaIsPositive: true,
      },
      // {
      //   label: getMonthNameAndYear(-3),
      //   val: responseData?.totalAmount4 || 0,
      //   sales: responseData?.totalSales4 || 0,
      //   delta: 1,
      //   deltaIsPositive: true,
      // },
      // {
      //   label: getMonthNameAndYear(-4),
      //   val: responseData?.totalAmount5 || 0,
      //   sales: responseData?.totalSales5 || 0,
      //   delta: 1,
      //   deltaIsPositive: true,
      // },
      // {
      //   label: getMonthNameAndYear(-5),
      //   val: responseData?.totalAmount6 || 0,
      //   sales: responseData?.totalSales6 || 0,
      //   delta: 1,
      //   deltaIsPositive: true,
      // },
    ]);
  } catch (err) {}
};

// GET SALES IN PROGRESS ////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////
interface GetSalesInProgressProps extends FetchProps {
  setLoadedData: React.Dispatch<
    React.SetStateAction<{
      totalSales: number;
      totalAmount: number;
      totalPotProfit: number;
    }>
  >;
  fetchId: string;
}

export const getSalesInprogress = async (props: GetSalesInProgressProps) => {
  const { sendRequest, setLoadedData, auth, fetchId } = props;

  const fetchType: "MANAGER" | "USER" =
    fetchId === auth.managerId ? "MANAGER" : "USER";

  try {
    const responseData: {
      totalSales: number;
      totalAmount: number;
      totalPotProfit: number;
    } = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/analytics/sales-in-progress/${fetchType}/${fetchId}`,
      "GET",
      null,
      {
        Authorization: "Bearer " + auth.token,
      }
    );

    setLoadedData(responseData);
  } catch (err) {}
};

// GET LAST VIEWED PROPOSALS SALES //////////////////////////////////////
/////////////////////////////////////////////////////////////////////////
interface GetLastViewedProposalsSalesProps extends FetchProps {
  setLoadedData: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
  fetchId: string;
}

export const getLastViewedProposalsSales = async (
  props: GetLastViewedProposalsSalesProps
) => {
  const { sendRequest, setLoadedData, auth, fetchId } = props;

  const fetchType: "MANAGER" | "USER" =
    fetchId === auth.managerId ? "MANAGER" : "USER";

  try {
    const responseData: {
      kanban: KanbanData;
      items: SalePopCustData[];
    } = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/analytics/viewed-proposals-sales/${fetchType}/${fetchId}`,
      "GET",
      null,
      {
        Authorization: "Bearer " + auth.token,
      }
    );

    setLoadedData(responseData);
  } catch (err) {}
};

// GET NEXT SCHEDULES ///////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////
interface GetNextSchedulesProps extends FetchProps {
  setLoadedData: React.Dispatch<React.SetStateAction<NotePopCustData[]>>;
  fetchId: string;
}

export const getNextSchedules = async (props: GetNextSchedulesProps) => {
  const { sendRequest, setLoadedData, auth, fetchId } = props;

  const fetchType: "MANAGER" | "USER" =
    fetchId === auth.managerId ? "MANAGER" : "USER";

  try {
    const responseData: {
      items: NotePopCustData[];
    } = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/analytics/next-schedules/${fetchType}/${fetchId}`,
      "GET",
      null,
      {
        Authorization: "Bearer " + auth.token,
      }
    );

    setLoadedData(responseData.items || []);
  } catch (err) {}
};
