import { Fragment, useState, useEffect } from "react";
import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  BarSeries,
  Legend,
} from "@devexpress/dx-react-chart-material-ui";
import { Stack } from "@devexpress/dx-react-chart";

import { addThousandSeparator } from "../../shared/util/addThousandSeparator";
import { sum } from "../../shared/util/sum";
import { setMonthlyGenerationData } from "../../shared/data/dynamic";
import Roots from "../../shared/components/MUIThemed/GraphLegendsRoot";
import Label from "../../shared/components/MUIThemed/GraphLegendsLabel";
import { AuthContextProps, ProposalData } from "../../shared/data/types";

import "./PropDash.scss";

interface PropDashProjectProps {
  auth: AuthContextProps;
  memory: ProposalData;
}

export const PropDashProject = (props: PropDashProjectProps) => {
  const { auth, memory } = props;
  const [verticalLegend, setVerticalLegend] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const monthlyGeneration = setMonthlyGenerationData({ proposal: memory });

  const resizeHandler = () => {
    if (window.innerWidth < 600) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }

    if (window.innerWidth < 1100) {
      setVerticalLegend(true);
    } else {
      setVerticalLegend(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);
    resizeHandler();

    var timer = setTimeout(() => {
      resizeHandler();
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <Fragment>
      {(!memory?.realSystemData?.averageMontlhyGeneration ||
        memory?.realSystemData?.averageMontlhyGeneration === 0) && (
        <p className="cust-dash__col-right-content-empty">
          Esta proposta não possui dados do projeto
        </p>
      )}
      {memory?.realSystemData?.averageMontlhyGeneration > 0 && (
        <div className="cust-dash__project">
          <Fragment>
            <p
              className="cust-dash__project-title"
              style={{
                color: "#263351",
                fontSize: "1.1rem",
                fontWeight: 700,
              }}
            >
              Projeto e Instalação
            </p>
            <div className="cust-dash__project-grid">
              <p className="cust-dash__project-grid-item cust-dash__project-grid-item--label">
                Potência do sistema:
              </p>{" "}
              <p className="cust-dash__project-grid-item cust-dash__project-grid-item--highlight">
                {`${addThousandSeparator(memory.realSystemData.power)} kWp`}
              </p>
              <p className="cust-dash__project-grid-item cust-dash__project-grid-item--label">
                {memory.tariffModality === "B"
                  ? "Consumo médio mensal de energia:"
                  : "Consumo médio mensal fora ponta:"}
              </p>
              <p className="cust-dash__project-grid-item">
                {`${addThousandSeparator(
                  memory.estimateSystemData.averageMontlhyConsumption
                )} kWh/mês`}
              </p>
              {memory.tariffModality === "A" && (
                <Fragment>
                  <p className="cust-dash__project-grid-item cust-dash__project-grid-item--label">
                    Consumo médio mensal ponta:
                  </p>
                  <p className="cust-dash__project-grid-item">
                    {`${addThousandSeparator(
                      Math.round(
                        memory.estimateSystemData.averageYearlyConsumptionPeak /
                          12
                      )
                    )} kWh/ano`}
                  </p>
                </Fragment>
              )}
              <p className="cust-dash__project-grid-item cust-dash__project-grid-item--label">
                {memory.tariffModality === "B"
                  ? "Consumo médio anual de energia:"
                  : "Consumo médio anual fora ponta:"}
              </p>
              <p className="cust-dash__project-grid-item">
                {`${addThousandSeparator(
                  sum(memory.totalMonthlyConsumption)
                )} kWh/ano`}
              </p>
              {memory.tariffModality === "A" && (
                <Fragment>
                  <p className="cust-dash__project-grid-item cust-dash__project-grid-item--label">
                    Consumo médio anual ponta:
                  </p>
                  <p className="cust-dash__project-grid-item">
                    {`${addThousandSeparator(
                      memory.estimateSystemData.averageYearlyConsumptionPeak
                    )} kWh/ano`}
                  </p>
                </Fragment>
              )}
              <p className="cust-dash__project-grid-item cust-dash__project-grid-item--label">
                Geração média mensal estimada:
              </p>
              <p className="cust-dash__project-grid-item">
                {`${addThousandSeparator(
                  memory.realSystemData.averageMontlhyGeneration
                )} kWh/mês`}
              </p>
              <p className="cust-dash__project-grid-item cust-dash__project-grid-item--label">
                Geração média anual estimada:
              </p>
              <p className="cust-dash__project-grid-item">
                {`${addThousandSeparator(
                  memory.realSystemData.averageYearlyGeneration
                )} kWh/ano`}
              </p>
            </div>
            {!isMobile && (
              <div className="cust-dash__project-chart u-margin-top-md">
                <Chart height={400} data={monthlyGeneration}>
                  <ArgumentAxis />
                  <ValueAxis />
                  <BarSeries
                    name="Consumo (kWh)"
                    color="#999"
                    valueField="consumption"
                    argumentField="month"
                  />
                  <BarSeries
                    name="Geração (kWh)"
                    color="#263351"
                    valueField="generation"
                    argumentField="month"
                  />
                  <Stack />
                  <Legend
                    position="bottom"
                    rootComponent={
                      verticalLegend ? Roots.RootMobile : Roots.Root
                    }
                    labelComponent={Label}
                  />
                </Chart>
              </div>
            )}
          </Fragment>
        </div>
      )}
    </Fragment>
  );
};
