import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { ModalConfirmStyles } from "./ModalConfirmStyles";

export interface ModalConfirmProps {
  open: boolean;
  closeHandler: () => void;
  confirmHandler: () => void | Promise<void>;
  message: string;
  confirmOnly?: boolean;
}

export const ModalConfirm = (props: ModalConfirmProps) => {
  const { open, closeHandler, confirmHandler, message, confirmOnly } = props;
  const classes = ModalConfirmStyles();

  return (
    <div>
      <Dialog style={{ zIndex: 10002 }} open={open} onClose={closeHandler}>
        <DialogTitle className={classes.confirmModalTitle}>AVISO</DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.confirmModalContent}>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={confirmHandler} color="primary">
            {!confirmOnly ? "Sim" : "Entendi"}
          </Button>
          {!confirmOnly && (
            <Button onClick={closeHandler} color="secondary">
              Não
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};
