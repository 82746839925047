import React, { Fragment, useEffect, useState } from "react";
import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import {
  moveSingleSaleOwner,
  moveColumnSalesOwner,
  moveSelectedSalesOwner,
} from "../api/salesFunnelAPI";
import {
  AuthContextProps,
  KanbanFilledData,
  SalePopCustData,
} from "../../shared/data/types";

import { PopoverFormStyles } from "./PopoverFormStyles";

interface PopoverMoveSaleProps {
  columnId?: string;
  itemId?: string;
  item?: SalePopCustData;
  itemsIds?: string[];
  setSelectedItems?: React.Dispatch<React.SetStateAction<string[]>>;
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLButtonElement>>;
  auth: AuthContextProps;
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean
  ) => Promise<any>;
  setKanbanData: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
  type: "column" | "single" | "selected";
  senderFetchId?: string;
  setActiveItem?: React.Dispatch<React.SetStateAction<string>>;
}

export const PopoverMoveSale = (props: PopoverMoveSaleProps) => {
  const {
    columnId,
    itemId,
    item,
    anchorEl,
    itemsIds,
    setAnchorEl,
    auth,
    sendRequest,
    setKanbanData,
    type,
    senderFetchId,
    setActiveItem,
    setSelectedItems,
  } = props;
  const open = Boolean(anchorEl);
  const classes = PopoverFormStyles();
  const [fetchId, setFetchId] = useState<string>(auth?.userId || "");
  const vendorsOptions =
    auth.vendors
      ?.filter((vendor) => {
        if (!item) {
          return vendor._id !== senderFetchId;
        } else {
          return vendor._id !== item.user?.toString();
        }
      })
      ?.map((vendor) => {
        return {
          value: vendor._id,
          option: `${vendor.personalData?.name} - ${vendor.email}`,
        };
      }) || [];

  const allOptions = [
    { value: auth.userId, option: "GERENTE" },
    ...vendorsOptions,
  ];

  useEffect(() => {
    setFetchId(auth?.userId || "");
  }, [senderFetchId]);

  const changeValueHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFetchId(e.target.value);
  };

  const moveSaleHandler = () => {
    if (type === "single") {
      moveSingleSaleOwner({
        sendRequest,
        auth,
        columnId,
        saleId: itemId,
        receiverId: fetchId,
        setKanbanData,
        senderFetchId,
      });
    } else if (type === "column") {
      moveColumnSalesOwner({
        sendRequest,
        auth,
        columnId,
        receiverId: fetchId,
        setKanbanData,
        senderFetchId,
      });
    } else if (type === "selected") {
      moveSelectedSalesOwner({
        sendRequest,
        auth,
        salesIds: itemsIds,
        receiverId: fetchId,
        setKanbanData,
        senderFetchId,
      }).then(() => {
        if (setSelectedItems) {
          setSelectedItems([]);
        }
      });
    }

    setAnchorEl(null);
    setFetchId(allOptions?.[0]?.value || "");

    if (setActiveItem) {
      setActiveItem(null);
    }
  };

  return (
    <Fragment>
      <Popover
        id={itemId + "-" + "move-sale-popover"}
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className={classes.formRoot}>
          <FormControl>
            <InputLabel id="fetch-type-filter-selector-label">
              Enviar para
            </InputLabel>
            <Select
              labelId="fetch-type-filter-selector-label"
              id="fetch-type-filter-selector"
              value={fetchId || allOptions?.[0]?.value}
              onChange={changeValueHandler}
            >
              {allOptions.map((option) => {
                return (
                  <MenuItem key={option.value} value={option.value}>
                    {option.option}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Button
            disabled={
              !fetchId ||
              (auth.userId === senderFetchId &&
                allOptions
                  .map((opt) => {
                    return opt.value;
                  })
                  .indexOf(fetchId) === 0) ||
              fetchId === item?.user?.toString()
            }
            color="primary"
            variant="contained"
            onClick={moveSaleHandler}
          >
            {type === "single" ? "ENVIAR VENDA" : "ENVIAR VENDAS"}
          </Button>
        </div>
      </Popover>
    </Fragment>
  );
};
