import Popover from "@material-ui/core/Popover";
import React, { Fragment } from "react";

import { PopoverFormStyles } from "../../../sales/components/PopoverFormStyles";
import { InputSelectRequiredwithId } from "../../../shared/components/FormElements/InputSelectRequiredwithId";
import {
  AuthContextProps,
  FormHookDispState,
} from "../../../shared/data/types";

interface PopoverSelectUserProps {
  anchorEl: HTMLButtonElement | HTMLLIElement | null;
  setAnchorEl: React.Dispatch<
    React.SetStateAction<HTMLButtonElement | HTMLLIElement>
  >;
  inputHandler: (
    id: string,
    value: string | number | boolean | string[] | File,
    isValid: boolean,
    label: string
  ) => void;
  usersOpts: {
    txt: string;
    id: string;
  }[];
  auth: AuthContextProps;
  formState: FormHookDispState;
  isLoading: boolean;
}

export const PopoverSelectUser = (props: PopoverSelectUserProps) => {
  const {
    anchorEl,
    setAnchorEl,
    inputHandler,
    usersOpts,
    auth,
    formState,
    isLoading,
  } = props;
  const open = Boolean(anchorEl);
  const classes = PopoverFormStyles();

  return (
    <Fragment>
      <Popover
        id="popover-select-user"
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        style={{ zIndex: 502 }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <form className={classes.formRoot}>
          <InputSelectRequiredwithId
            id="user"
            label="Filtrar por"
            variant="outlined"
            onInput={inputHandler}
            options={usersOpts}
            initialValue={
              formState?.inputs?.user?.value?.toString() ||
              usersOpts?.[0]?.id ||
              auth?.managerId
            }
            disabled={isLoading}
            resetOnOptionsChange
          />
        </form>
      </Popover>
    </Fragment>
  );
};
