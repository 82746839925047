import { useEffect, useState } from "react";
import { Chart, PieSeries } from "@devexpress/dx-react-chart-material-ui";
import { Animation } from "@devexpress/dx-react-chart";
import { Palette } from "@devexpress/dx-react-chart";

import { useHttpClient } from "../../../shared/hooks/httpHook";
import { SaleDash } from "../../../sales/components/SaleDash";
import { ModalError } from "../../../shared/components/UIElements/ModalError";
import { LoadingSpinnerOverlayRegular } from "../../../shared/components/UIElements/LoadingSpinnerOverlayRegular";
import { HomeDashboardViewedPropsSaleItem } from "./HomeDashboardViewedPropsSaleItem";
import { addThousandSeparator } from "../../../shared/util/addThousandSeparator";
import { getMonthNameAndYear } from "../../../shared/util/getMonthNameAndYear";
import { getLastViewedProposalsSales } from "../../api/analyticsAPI";
import {
  AuthContextProps,
  SettingsContextProps,
  KanbanFilledData,
} from "../../../shared/data/types";

import "./HomeDashboard.scss";

interface HomeDashboardViewedPropsSalesProps {
  settingsCtx: SettingsContextProps;
  auth: AuthContextProps;
  fetchId: string;
  loadedData: KanbanFilledData;
  setLoadedData: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
}

export const HomeDashboardViewedPropsSales = (
  props: HomeDashboardViewedPropsSalesProps
) => {
  const [activeItem, setActiveItem] = useState<string>(null);
  const { settingsCtx, auth, fetchId, loadedData, setLoadedData } = props;
  const { error, sendRequest, clearError, isLoading } = useHttpClient();

  useEffect(() => {
    if (!!auth.token) {
      getLastViewedProposalsSales({
        sendRequest,
        auth,
        setLoadedData,
        fetchId,
      });
    }
  }, [fetchId, auth?.token]);

  const closeSaleDashHandler = () => {
    setActiveItem(null);
  };

  return (
    <div className="dashboard-item dashboard-item--viewed-props">
      {isLoading && <LoadingSpinnerOverlayRegular />}
      {/* <ModalError error={error} onClear={clearError} /> */}
      <p className="dashboard-item__title dashboard-item__title--padded">
        Últimas Propostas Visualizadas
      </p>
      <div className="dashboard-item__list">
        {loadedData?.items?.map((item, i) => {
          return (
            <HomeDashboardViewedPropsSaleItem
              item={item}
              key={item._id}
              fetchId={fetchId}
              kanbanData={loadedData}
              setLoadedData={setLoadedData}
              setActiveItem={setActiveItem}
              settingsCtx={settingsCtx}
              auth={auth}
            />
          );
        })}
      </div>
      {loadedData?.items?.length === 0 && (
        <div className="dashboard-item__no-data">
          <img
            className="dashboard-item__no-data-img"
            src={`${process.env.PUBLIC_URL}/nenhum-dado.png`}
            alt=""
          />
        </div>
      )}
      <SaleDash
        auth={auth}
        settingsCtx={settingsCtx}
        active={!!activeItem}
        closeHandler={closeSaleDashHandler}
        loadedItem={loadedData?.items?.find((item) => {
          return item?.id === activeItem;
        })}
        setLoadedData={setLoadedData}
        setActiveItem={setActiveItem}
        fetchId={fetchId}
        type="SALES"
        kanbanData={loadedData}
      />
    </div>
  );
};
