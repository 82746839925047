import { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import SendRoundedIcon from "@material-ui/icons/SendRounded";
import DeleteForeverRoundedIcon from "@material-ui/icons/DeleteForeverRounded";
import AttachMoneyRoundedIcon from "@material-ui/icons/AttachMoneyRounded";
import MoneyOffRoundedIcon from "@material-ui/icons/MoneyOffRounded";
import KeyboardReturnRoundedIcon from "@material-ui/icons/KeyboardReturnRounded";
import DoneOutlineRoundedIcon from "@material-ui/icons/DoneOutlineRounded";

import { ModalConfirm } from "../../shared/components/UIElements/ModalConfirm";
import { LoadingSpinnerOverlayRegular } from "../../shared/components/UIElements/LoadingSpinnerOverlayRegular";
import { PopoverFormClosedSalePropChoice } from "./PopoverFormClosedSalePropChoice";
import { PopoverFormLostSaleReasonChoice } from "./PopoverFormLostSaleReasonChoice";
import { PopoverFormReturnSaleToFunnel } from "./PopoverFormReturnSaleToFunnel";
import { PopoverMoveSale } from "./PopoverMoveSale";
import { getSaleTypeClientData } from "../util/getSaleTypeClientData";
import { deleteItem } from "../api/salesFunnelAPI";
import { editSaleStatus } from "../api/salesFunnelAPI";
import {
  AuthContextProps,
  KanbanFilledData,
  SettingsContextData,
} from "../../shared/data/types";

interface MenuSalesItemOptionsProps {
  id: string;
  item: KanbanFilledData["items"][0];
  customerId: string;
  columnId: string;
  anchorEl: HTMLButtonElement;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLButtonElement>>;
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean
  ) => Promise<any>;
  auth: AuthContextProps;
  settingsCtx: SettingsContextData;
  kanbanData: KanbanFilledData;
  setKanbanData: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
  fetchId: string;
  type: "SALES" | "PROJECT" | "INSTALLATION" | "AFTER_SALES";
  setActiveItem?: React.Dispatch<React.SetStateAction<string>>;
  setSelectedItems?: React.Dispatch<React.SetStateAction<string[]>>;
  isLoading?: boolean;
  setUpdateColumnsTotals?: React.Dispatch<React.SetStateAction<string[]>>;
}

export const MenuSalesItemOptions = (props: MenuSalesItemOptionsProps) => {
  const {
    item,
    customerId,
    columnId,
    anchorEl,
    setAnchorEl,
    sendRequest,
    auth,
    id,
    setKanbanData,
    fetchId,
    setActiveItem,
    kanbanData,
    settingsCtx,
    type,
    setSelectedItems,
    isLoading,
    setUpdateColumnsTotals,
  } = props;
  const history = useHistory();
  const [anchorElFormMove, setAnchorElFormMove] =
    useState<null | HTMLButtonElement>(null);
  const [anchorElFormCloseSale, setAnchorElFormCloseSale] = useState<
    null | HTMLButtonElement | HTMLLIElement
  >(null);
  const [anchorElFormLostSale, setAnchorElFormLostSale] = useState<
    null | HTMLButtonElement | HTMLLIElement
  >(null);
  const [anchorElFormReturn, setAnchorElFormReturn] = useState<
    null | HTMLButtonElement | HTMLLIElement
  >(null);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [showConfirmFinishModal, setShowConfirmFinishModal] =
    useState<boolean>(false);
  const { typeName, registerUrl } = getSaleTypeClientData(type, auth.userId);

  const finishTaskHandler = () => {
    setShowConfirmFinishModal(false);
    editSaleStatus({
      sendRequest,
      auth,
      status: "CLOSED",
      approvedProposal: "",
      itemId: item?._id,
      setKanbanData,
      toBeRemovedItem: item,
    }).then(() => {
      if (setActiveItem) {
        setActiveItem(null);
      }
      if (setUpdateColumnsTotals) {
        setUpdateColumnsTotals([columnId, "0"]);
      }
    });
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <ModalConfirm
        open={showConfirmFinishModal}
        closeHandler={() => {
          setShowConfirmFinishModal(false);
        }}
        confirmHandler={() => {
          finishTaskHandler();
        }}
        message="Deseja marcar este item como finalizado?"
      />
      <ModalConfirm
        open={showConfirmModal}
        closeHandler={() => {
          setShowConfirmModal(false);
        }}
        confirmHandler={() => {
          deleteItem({
            sendRequest,
            auth,
            setKanbanData,
            itemId: item?._id,
            toBeDeletedItem: item,
            setShowConfirmModal,
          }).then(() => {
            if (setActiveItem) {
              setActiveItem(null);
            }
            if (setUpdateColumnsTotals) {
              setUpdateColumnsTotals([columnId, "0"]);
            }
          });
        }}
        message="Deseja excluir este item permanentemente?"
      />
      {type === "SALES" && (
        <PopoverMoveSale
          columnId={columnId}
          itemId={id}
          item={item}
          anchorEl={anchorElFormMove}
          setAnchorEl={setAnchorElFormMove}
          auth={auth}
          sendRequest={sendRequest}
          setKanbanData={setKanbanData}
          senderFetchId={fetchId}
          type="single"
          setActiveItem={setActiveItem}
          setSelectedItems={setSelectedItems}
        />
      )}
      {type === "SALES" && (
        <PopoverFormClosedSalePropChoice
          itemId={item?._id}
          item={item}
          anchorEl={anchorElFormCloseSale}
          setAnchorEl={setAnchorElFormCloseSale}
          auth={auth}
          sendRequest={sendRequest}
          setKanbanData={setKanbanData}
          setActiveItem={setActiveItem}
          settingsCtx={settingsCtx}
          type={type}
          setUpdateColumnsTotals={setUpdateColumnsTotals}
        />
      )}
      {type === "SALES" && (
        <PopoverFormLostSaleReasonChoice
          itemId={item?._id}
          item={item}
          anchorEl={anchorElFormLostSale}
          setAnchorEl={setAnchorElFormLostSale}
          auth={auth}
          sendRequest={sendRequest}
          setKanbanData={setKanbanData}
          setActiveItem={setActiveItem}
          setUpdateColumnsTotals={setUpdateColumnsTotals}
        />
      )}
      <PopoverFormReturnSaleToFunnel
        itemId={item?._id}
        item={item}
        kanbanData={kanbanData}
        anchorEl={anchorElFormReturn}
        setAnchorEl={setAnchorElFormReturn}
        auth={auth}
        sendRequest={sendRequest}
        setKanbanData={setKanbanData}
        setActiveItem={setActiveItem}
      />
      <div>
        <Menu
          style={{ zIndex: 502 }}
          id="opcoes-item-funil"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => {
            setAnchorEl(null);
          }}
        >
          {isLoading && <LoadingSpinnerOverlayRegular />}
          {item?.status === "IN_PROGRESS" && type === "SALES" && (
            <MenuItem
              style={{ color: "#007bed" }}
              onClick={(e) => {
                setAnchorEl(null);
                setAnchorElFormCloseSale(e.currentTarget);
              }}
            >
              <AttachMoneyRoundedIcon style={{ marginRight: "0.75rem" }} /> Dar
              Ganho
            </MenuItem>
          )}
          {item?.status === "IN_PROGRESS" && type !== "SALES" && (
            <MenuItem
              style={{ color: "#007bed" }}
              onClick={(e) => {
                setAnchorEl(null);
                setShowConfirmFinishModal(true);
              }}
            >
              <DoneOutlineRoundedIcon style={{ marginRight: "0.75rem" }} />{" "}
              Finalizar
            </MenuItem>
          )}
          {item?.status === "IN_PROGRESS" && type === "SALES" && (
            <MenuItem
              style={{ color: "#f50057" }}
              onClick={(e) => {
                setAnchorEl(null);
                setAnchorElFormLostSale(e.currentTarget);
              }}
            >
              <MoneyOffRoundedIcon style={{ marginRight: "0.75rem" }} /> Dar
              Perdido
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              history.push(`${registerUrl}?saleId=${item?.id}`);
            }}
          >
            <EditRoundedIcon style={{ marginRight: "0.75rem" }} />{" "}
            {`Editar ${typeName}`}
          </MenuItem>
          {auth.type === "MANAGER" && type === "SALES" && (
            <MenuItem
              onClick={() => {
                setAnchorElFormMove(anchorEl);
                setAnchorEl(null);
              }}
            >
              <SendRoundedIcon style={{ marginRight: "0.75rem" }} /> Enviar
              Venda
            </MenuItem>
          )}
          {item?.status !== "IN_PROGRESS" && (
            <MenuItem
              onClick={(e) => {
                setAnchorEl(null);
                setAnchorElFormReturn(e.currentTarget);
              }}
            >
              <KeyboardReturnRoundedIcon style={{ marginRight: "0.75rem" }} />{" "}
              {`Retornar ao ${type === "SALES" ? "Funil" : "Quadro"}`}
            </MenuItem>
          )}
          {auth.type !== "VENDOR" && (
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                setShowConfirmModal(true);
              }}
            >
              <DeleteForeverRoundedIcon style={{ marginRight: "0.75rem" }} />{" "}
              {`Excluir ${typeName}`}
            </MenuItem>
          )}
        </Menu>
      </div>
    </Fragment>
  );
};
