import { useEffect, useState } from "react";
import { Chart, PieSeries } from "@devexpress/dx-react-chart-material-ui";
import { Animation } from "@devexpress/dx-react-chart";
import { Palette } from "@devexpress/dx-react-chart";

import { useHttpClient } from "../../../shared/hooks/httpHook";
import { ModalError } from "../../../shared/components/UIElements/ModalError";
import { LoadingSpinnerOverlayRegular } from "../../../shared/components/UIElements/LoadingSpinnerOverlayRegular";
import { addThousandSeparator } from "../../../shared/util/addThousandSeparator";
import { getMonthNameAndYear } from "../../../shared/util/getMonthNameAndYear";
import { getGenSales } from "../../api/analyticsAPI";
import {
  AuthContextProps,
  SettingsContextProps,
} from "../../../shared/data/types";

import "./HomeDashboard.scss";
import { is } from "date-fns/locale";

interface HomeDashboardGenSalesProps {
  settingsCtx: SettingsContextProps;
  auth: AuthContextProps;
  fetchId: string;
  loadedData: {
    label: string;
    val: number;
    sales: number;
    delta: number;
    deltaIsPositive: boolean;
  }[];
  setLoadedData: React.Dispatch<
    React.SetStateAction<
      {
        label: string;
        val: number;
        sales: number;
        delta: number;
        deltaIsPositive: boolean;
      }[]
    >
  >;
  setIsLoadingGenSales: React.Dispatch<React.SetStateAction<boolean>>;
}

export const HomeDashboardGenSales = (props: HomeDashboardGenSalesProps) => {
  const {
    settingsCtx,
    auth,
    fetchId,
    loadedData,
    setLoadedData,
    setIsLoadingGenSales,
  } = props;
  const { error, sendRequest, clearError, isLoading } = useHttpClient();

  useEffect(() => {
    if (!!auth.token) {
      getGenSales({ sendRequest, auth, setLoadedData, fetchId });
    }
  }, [fetchId, auth?.token]);

  useEffect(() => {
    setIsLoadingGenSales(isLoading);
  }, [isLoading]);

  const hasData =
    loadedData.length > 0
      ? !!loadedData?.find((data) => {
          return data.val > 0;
        })
      : false;

  return (
    <div className="dashboard-item dashboard-item--gen-sales">
      {isLoading && <LoadingSpinnerOverlayRegular />}
      {/* <ModalError error={error} onClear={clearError} /> */}
      <p className="dashboard-item__title">Negócios Gerados (R$)</p>
      {loadedData.length > 0 && hasData && (
        <div className="dashboard-item__doughnut-chart">
          <Chart height={250} data={loadedData?.slice(0, 3)}>
            <Palette scheme={["#05445e", "#189ab4", "#75e6da", "#d4f1f4"]} />
            <PieSeries
              valueField="val"
              argumentField="label"
              innerRadius={0.6}
            />
            <Animation />
          </Chart>
        </div>
      )}
      {loadedData.length > 0 && !hasData && (
        <div className="dashboard-item__doughnut-chart">
          <Chart height={250} data={[{ val: 1, label: "" }]}>
            <Palette scheme={["#d4f1f4"]} />
            <PieSeries
              valueField="val"
              argumentField="label"
              innerRadius={0.6}
            />
            <Animation />
          </Chart>
        </div>
      )}
      <div className="dashboard-item__legends">
        <div className="dashboard-item__legend">
          <div className="dashboard-item__legend-color dashboard-item__legend-color--1"></div>
          <div className="dashboard-item__legend-data">
            <p className="dashboard-item__legend-value">
              {addThousandSeparator(loadedData?.[0]?.val || 0)?.split(",")?.[0]}
              <span className="dashboard-item__legend-value--cents">
                {
                  addThousandSeparator(loadedData?.[0]?.val || 0)?.split(
                    ","
                  )?.[1]
                }
              </span>
              {!!loadedData?.[0]?.delta && (
                <span
                  className={`dashboard-item__legend-value-delta ${
                    loadedData?.[0]?.deltaIsPositive
                      ? "dashboard-item__legend-value-delta--pos"
                      : "dashboard-item__legend-value-delta--neg"
                  }`}
                >
                  {addThousandSeparator(loadedData?.[0]?.delta || 0)
                    ?.split(",")?.[0]
                    ?.replace("Infinity", "∞")}
                  %
                </span>
              )}
            </p>
            <p className="dashboard-item__legend-period">
              {getMonthNameAndYear(0)}
            </p>
          </div>
        </div>
        <div className="dashboard-item__legend">
          <div className="dashboard-item__legend-color dashboard-item__legend-color--2"></div>
          <div className="dashboard-item__legend-data">
            <p className="dashboard-item__legend-value">
              {addThousandSeparator(loadedData?.[1]?.val || 0)?.split(",")?.[0]}
              <span className="dashboard-item__legend-value--cents">
                {
                  addThousandSeparator(loadedData?.[1]?.val || 0)?.split(
                    ","
                  )?.[1]
                }
              </span>
              {!!loadedData?.[1]?.delta && (
                <span
                  className={`dashboard-item__legend-value-delta ${
                    loadedData?.[1]?.deltaIsPositive
                      ? "dashboard-item__legend-value-delta--pos"
                      : "dashboard-item__legend-value-delta--neg"
                  }`}
                >
                  {addThousandSeparator(loadedData?.[1]?.delta || 0)
                    ?.split(",")?.[0]
                    ?.replace("Infinity", "∞")}
                  %
                </span>
              )}
            </p>
            <p className="dashboard-item__legend-period">
              {getMonthNameAndYear(-1)}
            </p>
          </div>
        </div>
        <div className="dashboard-item__legend">
          <div className="dashboard-item__legend-color dashboard-item__legend-color--3"></div>
          <div className="dashboard-item__legend-data">
            <p className="dashboard-item__legend-value">
              {addThousandSeparator(loadedData?.[2]?.val || 0)?.split(",")?.[0]}
              <span className="dashboard-item__legend-value--cents">
                {
                  addThousandSeparator(loadedData?.[2]?.val || 0)?.split(
                    ","
                  )?.[1]
                }
              </span>
            </p>
            <p className="dashboard-item__legend-period">
              {getMonthNameAndYear(-2)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
