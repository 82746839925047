import { IDBPDatabase } from "idb";

import {
  KanbanFilledData,
  KanbanData,
  AuthContextProps,
  NoteData,
  NotePopCustData,
  FormHookDispState,
  SalePopCustData,
  TimePeriodData,
  SaleData,
  KanbanFilledDataResp,
} from "../../shared/data/types";

interface FetchProps {
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean,
    zeroResultsKey?: string
  ) => Promise<any>;
  auth: AuthContextProps;
}

// GET KANBAN //////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
interface GetKanbanProps extends FetchProps {
  setKanbanData: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
  kanbanData: KanbanFilledData;
  fetchId?: string;
  type: "SALES" | "PROJECT" | "INSTALLATION" | "AFTER_SALES";
  searchValue: string;
  filter1Active: boolean;
  filter1Value?: TimePeriodData;
  setLoadedFetchId?: React.Dispatch<React.SetStateAction<string>>;
  multiplier: number;
  hidration: boolean;
  setFirstLoad?: React.Dispatch<React.SetStateAction<boolean>>;
  setShowLoadMore?: React.Dispatch<React.SetStateAction<boolean>>;
  setMultiplier?: React.Dispatch<React.SetStateAction<number>>;
  setTotalLoadedItems?: React.Dispatch<React.SetStateAction<number>>;
  totalLoadedItems?: number;
}

export const getKanban = async (props: GetKanbanProps) => {
  const {
    sendRequest,
    auth,
    type,
    setKanbanData,
    fetchId,
    searchValue,
    filter1Active,
    filter1Value,
    setLoadedFetchId,
    multiplier,
    hidration,
    setShowLoadMore,
    setFirstLoad,
    setMultiplier,
    setTotalLoadedItems,
    totalLoadedItems = 0,
    kanbanData,
  } = props;

  let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/kanbans/single/${fetchId}`;

  try {
    if (setMultiplier && hidration) {
      setMultiplier(0);
    }

    const responseData: KanbanFilledDataResp = await sendRequest(
      apiUrl,
      "POST",
      JSON.stringify({
        searchValue,
        filter1Active,
        filter1Value,
        type,
        mult: hidration ? 0 : multiplier,
        totalLoadedItems: hidration ? 0 : totalLoadedItems,
        loadedItems: hidration ? [] : kanbanData?.items || [],
      }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      }
    );

    if (setShowLoadMore) {
      setShowLoadMore(responseData.hasMoreItems);
    }

    setKanbanData((prevValues) => {
      if (!!prevValues && prevValues?.items?.length > 0 && !hidration) {
        return {
          kanban: responseData.kanban,
          items: [...prevValues.items, ...responseData.items],
        };
      } else {
        return { kanban: responseData.kanban, items: responseData.items };
      }
    });

    if (setFirstLoad) {
      setFirstLoad(true);
    }

    if (setLoadedFetchId) {
      setLoadedFetchId(fetchId);
    }

    if (setTotalLoadedItems) {
      if (!hidration) {
        setTotalLoadedItems((prevValue) => {
          return prevValue + responseData?.items?.length || 0;
        });
      } else {
        setTotalLoadedItems(responseData?.items?.length || 0);
      }
    }
  } catch (err) {}
};

// MOVE COLUMN /////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
interface MoveColumnProps extends FetchProps {
  setKanbanData: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
  kid: string;
  columnId: string;
  order: number;
}

export const moveColumn = async (props: MoveColumnProps) => {
  const { sendRequest, auth, kid, order, columnId, setKanbanData } = props;

  let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/kanbans/single/column/order/${kid}/${columnId}`;

  try {
    const responseData: { kanban: KanbanData } = await sendRequest(
      apiUrl,
      "PUT",
      JSON.stringify({
        order,
      }),
      {
        Authorization: "Bearer " + auth.token,
        "Content-Type": "application/json",
      }
    );

    setKanbanData((prevValues) => {
      return { kanban: responseData.kanban, items: prevValues.items };
    });
  } catch (err) {}
};

// MOVE ITEM ///////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
interface MoveItemProps extends FetchProps {
  setKanbanData: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
  kid: string;
  itemId: string;
  column: string;
  order: number;
  fetchId: string;
}

export const moveItem = async (props: MoveItemProps) => {
  const {
    sendRequest,
    auth,
    kid,
    order,
    itemId,
    column,
    setKanbanData,
    fetchId,
  } = props;

  let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/kanbans/single/item/order/${kid}/${itemId}`;

  try {
    const responseData: KanbanFilledData = await sendRequest(
      apiUrl,
      "PUT",
      JSON.stringify({
        order,
        column,
        fetchId,
      }),
      {
        Authorization: "Bearer " + auth.token,
        "Content-Type": "application/json",
      }
    );

    setKanbanData((prevValues) => {
      return {
        kanban: responseData.kanban,
        items: prevValues.items?.map((item) => {
          const resItemsToUpdateIds =
            responseData.items?.map((it) => {
              return it?._id;
            }) || [];

          if (resItemsToUpdateIds.includes(item?._id)) {
            const foundResItem = responseData.items?.find((it) => {
              return it._id === item._id;
            });
            item = foundResItem;
          }

          return item;
        }),
      };
    });
  } catch (err) {}
};

// ADD COLUMN //////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
interface AddColumnProps extends FetchProps {
  title: string;
  inactivityWindow: number;
  setKanbanData: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
  kid: string;
}

export const addColumn = async (props: AddColumnProps) => {
  const { sendRequest, auth, setKanbanData, title, kid, inactivityWindow } =
    props;

  let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/kanbans/single/column/${kid}`;

  try {
    const responseData: {
      kanban: KanbanData;
    } = await sendRequest(
      apiUrl,
      "POST",
      JSON.stringify({ title, inactivityWindow }),
      {
        Authorization: "Bearer " + auth.token,
        "Content-Type": "application/json",
      }
    );

    setKanbanData((prevValues) => {
      return { kanban: responseData.kanban, items: prevValues.items };
    });
  } catch (err) {}
};

// EDIT COLUMN /////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
interface EditColumnProps extends FetchProps {
  title: string;
  inactivityWindow: number;
  columnId: string;
  setKanbanData: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
  kid: string;
}

export const editColumn = async (props: EditColumnProps) => {
  const {
    sendRequest,
    auth,
    setKanbanData,
    title,
    kid,
    columnId,
    inactivityWindow,
  } = props;

  let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/kanbans/single/column/title/${kid}/${columnId}`;

  try {
    const responseData: {
      kanban: KanbanData;
    } = await sendRequest(
      apiUrl,
      "PUT",
      JSON.stringify({ title, inactivityWindow }),
      {
        Authorization: "Bearer " + auth.token,
        "Content-Type": "application/json",
      }
    );

    setKanbanData((prevValues) => {
      return { kanban: responseData.kanban, items: prevValues.items };
    });
  } catch (err) {}
};

// DELETE COLUMN ///////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
interface DeleteColumnProps extends FetchProps {
  columnId: string;
  columnToMoveId: string;
  setKanbanData: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
  kid: string;
}

export const deleteColumn = async (props: DeleteColumnProps) => {
  const { sendRequest, auth, setKanbanData, kid, columnId, columnToMoveId } =
    props;

  let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/kanbans/single/column/${kid}/${columnId}/${columnToMoveId}`;
  try {
    const responseData: {
      kanban: KanbanData;
    } = await sendRequest(apiUrl, "DELETE", null, {
      Authorization: "Bearer " + auth.token,
      "Content-Type": "application/json",
    });

    setKanbanData((prevValues) => {
      return {
        kanban: responseData.kanban,
        items: prevValues.items?.map((item) => {
          if (item?.kanbanData?.column === columnId) {
            item.kanbanData.column = columnToMoveId;
          }
          return item;
        }),
      };
    });
  } catch (err) {}
};

interface DeleteItemProps extends FetchProps {
  itemId: string;
  toBeDeletedItem: KanbanFilledData["items"][0];
  setKanbanData: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
  setShowConfirmModal: React.Dispatch<React.SetStateAction<boolean>>;
}

// DELETE ITEM /////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
export const deleteItem = async (props: DeleteItemProps) => {
  const {
    sendRequest,
    auth,
    setKanbanData,
    itemId,
    setShowConfirmModal,
    toBeDeletedItem,
  } = props;
  let _: undefined;
  setShowConfirmModal(false);

  try {
    const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/sales/single/${auth.userId}/${itemId}`;
    await sendRequest(apiUrl, "DELETE", null, {
      Authorization: "Bearer " + auth.token,
    });

    setKanbanData((prevValues) => {
      const filteredItems = prevValues.items.filter((item) => {
        return item._id !== itemId;
      });

      const processedItems = filteredItems?.map((item) => {
        if (
          item.kanbanData?.column === toBeDeletedItem.kanbanData?.column &&
          item.kanbanData?.order > toBeDeletedItem?.kanbanData?.order
        ) {
          item.kanbanData.order -= 1;
        }

        return item;
      });

      return { kanban: prevValues.kanban, items: processedItems };
    });
  } catch (err) {}
};

// GET SALES DATA //////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
interface GetSalesDataProps extends FetchProps {
  setKanbanData: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
  setTotalProposals: React.Dispatch<React.SetStateAction<number>>;
  fetchId: string;
  setFirstLoad?: React.Dispatch<React.SetStateAction<boolean>>;
  timePeriodSelected: {
    timePeriodEarlierSelected: string;
    timePeriodOlderSelected: string;
  };
}

export const getSalesData = async (props: GetSalesDataProps) => {
  const {
    sendRequest,
    auth,
    setKanbanData,
    setTotalProposals,
    fetchId,
    setFirstLoad,
    timePeriodSelected,
  } = props;

  try {
    const responseData: {
      kanban: KanbanData;
      items: SalePopCustData[];
      totalProposals: number;
    } = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/kanbans/sales/${auth.userId}/${fetchId}`,
      "POST",
      JSON.stringify({ filter1Value: timePeriodSelected }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
      false,
      "items"
    );

    setKanbanData({ kanban: responseData.kanban, items: responseData.items });
    setTotalProposals(responseData.totalProposals || 0);

    if (setFirstLoad) {
      setFirstLoad(true);
    }
  } catch (err) {}
};

// NEW NOTE ////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
interface NewNoteProps extends FetchProps {
  id: string;
  noteText: string;
  noteType: string;
  warningDate: string;
  warningTimeStart: string;
  warningTimeEnd: string;
  setKanbanData: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
  setLoadedData?: React.Dispatch<React.SetStateAction<NoteData[]>>;
}

export const newNote = async (props: NewNoteProps) => {
  const {
    sendRequest,
    auth,
    id,
    noteText,
    noteType = "",
    warningDate = "",
    warningTimeEnd = "",
    warningTimeStart = "",
    setKanbanData,
    setLoadedData,
  } = props;

  try {
    const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/notes/new/${id}`;
    const responseData: {
      createdNote: NoteData;
    } = await sendRequest(
      apiUrl,
      "POST",
      JSON.stringify({
        noteText,
        noteType,
        warningDate,
        warningTimeStart,
        warningTimeEnd,
      }),
      {
        Authorization: "Bearer " + auth.token,
        "Content-Type": "application/json",
      }
    );

    if (setKanbanData) {
      setKanbanData((prevValues) => {
        if (!!prevValues) {
          const processesitems = prevValues?.items?.map((item) => {
            let itemNotes = item?.notes as NoteData[];
            if (item?._id === id) {
              return {
                ...item,
                notes: [...itemNotes, responseData.createdNote],
              };
            } else {
              return item;
            }
          });

          return { kanban: prevValues.kanban, items: processesitems };
        }
      });
    }

    if (setLoadedData && !!warningDate) {
      setLoadedData((prevValues) => {
        return [...prevValues, responseData.createdNote];
      });
    }
  } catch (err) {}
};

// EDIT NOTE ///////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
interface EditNoteProps extends FetchProps {
  sid: string;
  nid: string;
  noteText: string;
  noteType: string;
  warningDate: string;
  warningTimeStart: string;
  warningTimeEnd: string;
  setKanbanData?: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
  scheduleListElement: boolean;
  setLoadedData?: React.Dispatch<React.SetStateAction<NoteData[]>>;
}

export const editNote = async (props: EditNoteProps) => {
  const {
    sendRequest,
    auth,
    sid,
    nid,
    noteText,
    noteType = "",
    warningDate = "",
    warningTimeEnd = "",
    warningTimeStart = "",
    setKanbanData,
    setLoadedData,
  } = props;

  try {
    const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/notes/note/${sid}/${nid}`;

    const responseData: {
      editedNote: NoteData;
    } = await sendRequest(
      apiUrl,
      "PATCH",
      JSON.stringify({
        noteText,
        noteType,
        warningDate,
        warningTimeEnd,
        warningTimeStart,
      }),
      {
        Authorization: "Bearer " + auth.token,
        "Content-Type": "application/json",
      }
    );

    if (setKanbanData) {
      setKanbanData((prevValues) => {
        const processesitems = prevValues.items?.map((item) => {
          let itemNotes = item.notes as NoteData[];
          let processedNotes = itemNotes?.map((note) => {
            if (note._id === nid) {
              note.content = responseData.editedNote?.content || "";
              note.type = responseData.editedNote?.type || "";
              note.warning = responseData.editedNote?.warning || false;
              note.notificationDate =
                responseData.editedNote?.notificationDate || "";
              note.notificationTimestamp =
                responseData.editedNote?.notificationTimestamp || 0;
              note.notificationTimeStart =
                responseData.editedNote?.notificationTimeStart || "";
              note.notificationTimeEnd =
                responseData.editedNote?.notificationTimeEnd || "";
            }

            return note;
          });

          return { ...item, notes: processedNotes };
        });

        return { kanban: prevValues.kanban, items: processesitems };
      });
    }

    if (setLoadedData) {
      setLoadedData((prevValues) => {
        return prevValues.map((schedule) => {
          if (schedule._id === nid) {
            schedule.content = responseData.editedNote.content;
            schedule.warning = responseData.editedNote.warning;
            schedule.type = responseData.editedNote?.type || "";
            schedule.notificationDate =
              responseData.editedNote.notificationDate;
            schedule.notificationTimestamp =
              responseData.editedNote?.notificationTimestamp || 0;
            schedule.notificationTimeStart =
              responseData.editedNote?.notificationTimeStart || "";
            schedule.notificationTimeEnd =
              responseData.editedNote?.notificationTimeEnd || "";
          }
          return schedule;
        });
      });
    }
  } catch (err) {}
};

// DELETE NOTE /////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
interface DeleteNoteProps extends FetchProps {
  sid: string;
  nid: string;
  setKanbanData?: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
  scheduleListElement: boolean;
  setLoadedData?: React.Dispatch<React.SetStateAction<NotePopCustData[]>>;
}

export const deleteNote = async (props: DeleteNoteProps) => {
  const { sendRequest, auth, sid, nid, setKanbanData, setLoadedData } = props;

  try {
    const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/notes/note/${sid}/${nid}`;
    await sendRequest(apiUrl, "DELETE", null, {
      Authorization: "Bearer " + auth.token,
    });

    if (setKanbanData) {
      setKanbanData((prevValues) => {
        const processesitems = prevValues.items?.map((item) => {
          let itemNotes = item.notes as NoteData[];
          let filteredNotes = itemNotes?.filter((note) => {
            return note._id !== nid;
          });

          return { ...item, notes: filteredNotes };
        });

        return { kanban: prevValues.kanban, items: processesitems };
      });
    }

    if (setLoadedData) {
      setLoadedData((prevValues) => {
        return prevValues.filter((schedule) => {
          return schedule._id !== nid;
        });
      });
    }
  } catch (err) {}
};

// FETCH SCHEDULES /////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
interface FetchSchedulesProps extends FetchProps {
  setNetworkDataReceived: React.Dispatch<React.SetStateAction<boolean>>;
  setLoadedData: React.Dispatch<React.SetStateAction<NotePopCustData[]>>;
  setShowLoadMore: React.Dispatch<React.SetStateAction<boolean>>;
  multiplier: number;
  hidration: boolean;
  setFirstLoad?: React.Dispatch<React.SetStateAction<boolean>>;
  type: "SALES" | "PROJECT" | "INSTALLATION" | "AFTER_SALES";
}

export const fetchSchedules = async (props: FetchSchedulesProps) => {
  const {
    sendRequest,
    auth,
    setNetworkDataReceived,
    setLoadedData,
    setShowLoadMore,
    multiplier,
    hidration,
    setFirstLoad,
    type,
  } = props;

  try {
    const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/notes/schedules/paginated/${auth.userId}/${multiplier}/${type}`;
    const responseData: {
      schedules: NotePopCustData[];
      hasMoreItems: boolean;
    } = await sendRequest(apiUrl, "GET", null, {
      Authorization: "Bearer " + auth.token,
    });

    setShowLoadMore(responseData.hasMoreItems);
    setLoadedData((prevValues) => {
      if (!!prevValues && prevValues.length > 0 && !hidration) {
        return [...prevValues, ...responseData.schedules];
      } else {
        return responseData.schedules;
      }
    });
    setNetworkDataReceived(true);
    if (setFirstLoad) {
      setFirstLoad(true);
    }
  } catch (err) {}
};

// GET FILTERED SCHEDULES //////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
interface GetFilteredSchedulesProps extends FetchProps {
  setLoadedItems: React.Dispatch<React.SetStateAction<NotePopCustData[]>>;
  setFiltered: React.Dispatch<React.SetStateAction<boolean>>;
  searchValue: string;
  noteType: string;
  filter1Active: boolean;
  filter1Value: {
    timePeriodEarlierSelected: string;
    timePeriodOlderSelected: string;
  };
  setShowLoadMore: React.Dispatch<React.SetStateAction<boolean>>;
  multiplier: number;
  fetchId: string;
  setMultiplier?: React.Dispatch<React.SetStateAction<number>>;
  type: "SALES" | "PROJECT" | "INSTALLATION" | "AFTER_SALES" | "TODOS";
}

export const getFilteredSchedules = async (
  props: GetFilteredSchedulesProps
) => {
  const {
    auth,
    sendRequest,
    setLoadedItems,
    setFiltered,
    searchValue,
    noteType,
    filter1Active,
    filter1Value,
    setShowLoadMore,
    multiplier,
    setMultiplier,
    fetchId,
    type,
  } = props;

  let processedKbType = type === "TODOS" ? "ALL" : type;
  const fetchType =
    fetchId === auth.userId
      ? "USER"
      : fetchId === auth.managerId
      ? "MANAGER"
      : "VENDOR";

  try {
    const responseData: {
      schedules: NotePopCustData[];
      hasMoreItems: boolean;
    } = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/notes/schedules/filtered/${auth.userId}/${multiplier}`,
      "POST",
      JSON.stringify({
        fetchType,
        fetchId,
        searchValue,
        noteType,
        filter1Active,
        filter1Value,
        type: processedKbType,
      }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
      false,
      "schedules"
    );

    if (responseData && responseData.schedules?.length > 0) {
      setLoadedItems((prevValues) => {
        if (!!prevValues && prevValues.length > 0 && multiplier > 0) {
          return [...prevValues, ...responseData.schedules];
        } else {
          return responseData.schedules;
        }
      });
    }
    setFiltered(true);
    setShowLoadMore(responseData.hasMoreItems);
    if (setMultiplier) {
      setMultiplier(0);
    }
  } catch (err) {
    console.log(err);
  }
};

// FETCH SCHEDULES FROM IDB ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
interface FetchSchedulesFromIDBProps {
  networkDataReceived: boolean;
  openIndexedDBHandlerComp: () => Promise<IDBPDatabase<unknown>>;
  readAllDataInIdb: (idb: IDBPDatabase<unknown>, st: string) => Promise<any[]>;
  setLoadedData: React.Dispatch<React.SetStateAction<NotePopCustData[]>>;
}

export const fetchSchedulesFromIDB = async (
  props: FetchSchedulesFromIDBProps
) => {
  const {
    networkDataReceived,
    openIndexedDBHandlerComp,
    readAllDataInIdb,
    setLoadedData,
  } = props;

  const idb = await openIndexedDBHandlerComp();
  const storedData: {
    schedules: NotePopCustData[];
  }[] = await readAllDataInIdb(idb, "schedules");
  if (
    !networkDataReceived &&
    storedData &&
    storedData.length > 0 &&
    storedData[0]?.schedules?.length > 0 &&
    storedData[0].schedules?.[0]?.content
  ) {
    setLoadedData(storedData[0].schedules);
  }
};

// GET FILTERED SALE NOTES /////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
interface GetFilteredSaleNotesProps extends FetchProps {
  saleId: string;
  setKanbanData: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
  setFiltered: React.Dispatch<React.SetStateAction<boolean>>;
  noteType: string;
  noteCalendar: "TODAS AS NOTAS" | "NÃO AGENDADAS" | "AGENDADAS";
  filter1Active: boolean;
  filter1Value: {
    timePeriodEarlierSelected: string;
    timePeriodOlderSelected: string;
  };
  setShowLoadMore: React.Dispatch<React.SetStateAction<boolean>>;
  multiplier: number;
  setMultiplier?: React.Dispatch<React.SetStateAction<number>>;
  type: "SALES" | "PROJECT" | "INSTALLATION" | "AFTER_SALES";
}

export const getFilteredSaleNotes = async (
  props: GetFilteredSaleNotesProps
) => {
  const {
    saleId,
    auth,
    sendRequest,
    setKanbanData,
    setFiltered,
    noteType,
    noteCalendar,
    filter1Active,
    filter1Value,
    setShowLoadMore,
    multiplier,
    setMultiplier,
    type,
  } = props;

  try {
    const responseData: {
      notes: NotePopCustData[];
      hasMoreItems: boolean;
    } = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/notes/sale/${saleId}/${multiplier}`,
      "POST",
      JSON.stringify({
        noteCalendar,
        noteType,
        filter1Active,
        filter1Value,
        type,
      }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
      false,
      "schedules"
    );

    if (responseData && responseData.notes?.length > 0) {
      setKanbanData((prevValues) => {
        if (!!prevValues && prevValues.items?.length > 0 && multiplier > 0) {
          return {
            kanban: prevValues.kanban,
            items: [
              ...prevValues.items.map((item) => {
                if (item._id === saleId) {
                  item.notes = [
                    ...(item.notes as NotePopCustData[]),
                    ...responseData.notes,
                  ];
                }

                return item;
              }),
            ],
          };
        } else {
          return {
            kanban: prevValues.kanban,
            items: [
              ...prevValues.items.map((item) => {
                if (item._id === saleId) {
                  item.notes = responseData.notes;
                }

                return item;
              }),
            ],
          };
        }
      });
    }
    setFiltered(true);
    setShowLoadMore(responseData.hasMoreItems);
    if (setMultiplier) {
      setMultiplier(0);
    }
  } catch (err) {
    console.log(err);
  }
};

// GET SALE BY ID //////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
interface GetSaleByIdProps extends FetchProps {
  saleId: string;
  setLoadedSale: React.Dispatch<React.SetStateAction<SalePopCustData>>;
}

export const getSaleById = async (props: GetSaleByIdProps) => {
  const { sendRequest, auth, saleId, setLoadedSale } = props;

  try {
    const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/sales/single/${saleId}`;
    const responseData: { sale: SalePopCustData } = await sendRequest(
      apiUrl,
      "GET",
      null,
      {
        Authorization: "Bearer " + auth.token,
      }
    );

    setLoadedSale(responseData.sale);
  } catch (err) {}
};

// CREATE SALE /////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
interface CreateSaleProps extends FetchProps {
  formState: FormHookDispState;
  customerId: string;
  chosenProp: string;
  type: "SALES" | "PROJECT" | "INSTALLATION" | "AFTER_SALES";
}

export const createSale = async (props: CreateSaleProps) => {
  const { sendRequest, auth, formState, customerId, chosenProp, type } = props;

  try {
    const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/sales/new/${auth.userId}/${customerId}`;
    await sendRequest(
      apiUrl,
      "POST",
      JSON.stringify({
        amount: +formState.inputs?.amount?.value || 0,
        name: formState.inputs?.saleName?.value || "",
        validityDate: formState?.inputs?.enableValidity?.value
          ? formState?.inputs?.validityDate?.value
          : null,
        proposal: chosenProp || null,
        type,
      }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
      true
    );
  } catch (err) {}
};

// EDIT SALE ///////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
interface EditSaleProps extends FetchProps {
  formState: FormHookDispState;
  saleId: string;
  chosenProp: string;
  type: "SALES" | "PROJECT" | "INSTALLATION" | "AFTER_SALES";
}

export const editSale = async (props: EditSaleProps) => {
  const { sendRequest, auth, formState, saleId, chosenProp, type } = props;

  try {
    const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/sales/single/${auth.userId}/${saleId}`;
    await sendRequest(
      apiUrl,
      "PATCH",
      JSON.stringify({
        amount: +formState.inputs?.amount?.value || 0,
        name: formState.inputs?.saleName?.value || "",
        validityDate: formState?.inputs?.enableValidity?.value
          ? formState?.inputs?.validityDate?.value
          : null,
        proposal: chosenProp || null,
        type,
      }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
      true
    );
  } catch (err) {}
};

// EDIT SALE STATUS ////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
interface EditSaleStatusProps extends FetchProps {
  status: "IN_PROGRESS" | "CLOSED" | "LOST";
  approvedProposal?: string;
  chosenReason?: string;
  itemId: string;
  column?: string;
  dueDate?: string;
  setKanbanData?: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
  toBeRemovedItem?: KanbanFilledData["items"][0];
}

export const editSaleStatus = async (props: EditSaleStatusProps) => {
  const {
    sendRequest,
    auth,
    chosenReason,
    itemId,
    status,
    approvedProposal,
    column,
    setKanbanData,
    toBeRemovedItem,
    dueDate,
  } = props;

  try {
    const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/sales/single/status/${itemId}`;
    await sendRequest(
      apiUrl,
      "PUT",
      JSON.stringify({
        status: status,
        saleLostReason: chosenReason || "",
        approvedProposal: approvedProposal || "",
        column: column || "",
        dueDate: dueDate || "",
      }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
      true
    );

    if (setKanbanData) {
      setKanbanData((prevValues) => {
        const filteredItems = prevValues.items.filter((item) => {
          return item._id !== itemId;
        });

        const processedItems = filteredItems?.map((item) => {
          if (
            item.kanbanData?.column === toBeRemovedItem.kanbanData?.column &&
            item.kanbanData?.order > toBeRemovedItem?.kanbanData?.order
          ) {
            item.kanbanData.order -= 1;
          }

          return item;
        });

        return { kanban: prevValues.kanban, items: processedItems };
      });
    }
  } catch (err) {}
};

// MOVE SINGLE SALE OWNER //////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
interface MoveSingleSaleOwnerProps extends FetchProps {
  saleId: string;
  columnId: string;
  receiverId: string;
  setKanbanData: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
  senderFetchId: string;
}

export const moveSingleSaleOwner = async (props: MoveSingleSaleOwnerProps) => {
  const {
    sendRequest,
    auth,
    setKanbanData,
    saleId,
    columnId,
    receiverId,
    senderFetchId,
  } = props;

  try {
    const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/sales/single/user/${auth.userId}/${columnId}/${saleId}/${receiverId}`;
    await sendRequest(
      apiUrl,
      "PATCH",
      null,
      {
        Authorization: "Bearer " + auth.token,
      },
      true
    );

    if (senderFetchId !== auth.managerId && senderFetchId !== receiverId) {
      setKanbanData((prevValues) => {
        const filteredItems = prevValues.items.filter((item) => {
          return item._id !== saleId;
        });

        return { kanban: prevValues.kanban, items: filteredItems };
      });
    } else if (senderFetchId === auth.managerId) {
      setKanbanData((prevValues) => {
        const filteredItems = prevValues.items.map((item) => {
          if (item._id === saleId) {
            item.user =
              receiverId as unknown as KanbanFilledData["items"][0]["user"];
          }
          return item;
        });

        return { kanban: prevValues.kanban, items: filteredItems };
      });
    }
  } catch (err) {}
};

// MOVE COLUMN SALES OWNER /////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
interface MoveColumnSalesOwnerProps extends FetchProps {
  columnId: string;
  receiverId: string;
  setKanbanData: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
  senderFetchId: string;
}

export const moveColumnSalesOwner = async (
  props: MoveColumnSalesOwnerProps
) => {
  const {
    sendRequest,
    auth,
    setKanbanData,
    columnId,
    receiverId,
    senderFetchId,
  } = props;

  try {
    const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/sales/column/user/${auth.userId}/${columnId}/${receiverId}/${senderFetchId}`;
    await sendRequest(
      apiUrl,
      "PATCH",
      null,
      {
        Authorization: "Bearer " + auth.token,
      },
      true
    );

    if (senderFetchId !== auth.managerId && senderFetchId !== receiverId) {
      setKanbanData((prevValues) => {
        const filteredItems = prevValues.items.filter((item) => {
          return item.kanbanData.column !== columnId;
        });

        // console.log(filteredItems);

        return { kanban: prevValues.kanban, items: filteredItems };
      });
    }
  } catch (err) {}
};

// MOVE SELECTED SALES OWNER ///////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
interface MoveSelectedSalesOwnerProps extends FetchProps {
  receiverId: string;
  setKanbanData: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
  senderFetchId: string;
  salesIds: string[];
}

export const moveSelectedSalesOwner = async (
  props: MoveSelectedSalesOwnerProps
) => {
  const {
    sendRequest,
    auth,
    setKanbanData,
    receiverId,
    salesIds,
    senderFetchId,
  } = props;

  try {
    const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/sales/selected/${auth.userId}/${receiverId}/${senderFetchId}`;
    await sendRequest(
      apiUrl,
      "PATCH",
      JSON.stringify({ salesIds: salesIds || [] }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
      true
    );

    if (senderFetchId !== auth.managerId && senderFetchId !== receiverId) {
      setKanbanData((prevValues) => {
        const filteredItems = prevValues.items.filter((item) => {
          return !salesIds.includes(item?._id);
        });

        // console.log(filteredItems);

        return { kanban: prevValues.kanban, items: filteredItems };
      });
    } else if (senderFetchId === auth.managerId) {
      setKanbanData((prevValues) => {
        const filteredItems = prevValues.items.map((item) => {
          if (salesIds.includes(item._id)) {
            item.user =
              receiverId as unknown as KanbanFilledData["items"][0]["user"];
          }
          return item;
        });

        return { kanban: prevValues.kanban, items: filteredItems };
      });
    }
  } catch (err) {}
};

// DELETE SALE /////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
interface DeleteSaleProps extends FetchProps {
  saleId: string;
  setLoadedData: React.Dispatch<React.SetStateAction<SalePopCustData[]>>;
}

export const deleteSale = async (props: DeleteSaleProps) => {
  const { sendRequest, auth, setLoadedData, saleId } = props;

  try {
    const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/sales/single/${auth.userId}/${saleId}`;
    await sendRequest(
      apiUrl,
      "DELETE",
      null,
      {
        Authorization: "Bearer " + auth.token,
      },
      true
    );

    setLoadedData((prevValues) => {
      return [
        ...prevValues.filter((sale) => {
          return sale._id !== saleId;
        }),
      ];
    });
  } catch (err) {}
};

// DELETE SELECTED ITEMS ///////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
interface DeleteSelectedItemsProps extends FetchProps {
  salesIds: string[];
  setKanbanData: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
}

export const deleteSelectedItems = async (props: DeleteSelectedItemsProps) => {
  const { sendRequest, auth, setKanbanData, salesIds } = props;

  try {
    const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/sales/selected/${auth.userId}`;
    await sendRequest(
      apiUrl,
      "POST",
      JSON.stringify({ salesIds }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
      true
    );

    setKanbanData((prevValues) => {
      const deletedSales = prevValues?.items?.filter((sale) => {
        return salesIds.includes(sale._id);
      });

      deletedSales.forEach((sale) => {
        let higherOrderColumnSales = prevValues?.items?.filter((s) => {
          return (
            s.kanbanData?.column === sale.kanbanData?.column &&
            s.kanbanData?.order > sale.kanbanData?.order
          );
        });

        higherOrderColumnSales?.forEach((s) => {
          s.kanbanData.order -= 1;
        });
      });

      return {
        kanban: prevValues.kanban,
        items: prevValues.items?.filter((sale) => {
          return !salesIds.includes(sale._id);
        }),
      };
    });
  } catch (err) {}
};

// EDIT SELECTED SALES STATUS //////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
interface EditSelectedSalesStatusProps extends FetchProps {
  salesIds: string[];
  setKanbanData: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
}

export const editSelectedSalesStatus = async (
  props: EditSelectedSalesStatusProps
) => {
  const { sendRequest, auth, setKanbanData, salesIds } = props;

  try {
    const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/sales/selected/status`;
    await sendRequest(
      apiUrl,
      "PUT",
      JSON.stringify({ salesIds, status: "CLOSED" }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
      true
    );

    setKanbanData((prevValues) => {
      const deletedSales = prevValues?.items?.filter((sale) => {
        return salesIds.includes(sale._id);
      });

      deletedSales.forEach((sale) => {
        let higherOrderColumnSales = prevValues?.items?.filter((s) => {
          return (
            s.kanbanData?.column === sale.kanbanData?.column &&
            s.kanbanData?.order > sale.kanbanData?.order
          );
        });

        higherOrderColumnSales?.forEach((s) => {
          s.kanbanData.order -= 1;
        });
      });

      return {
        kanban: prevValues.kanban,
        items: prevValues.items?.filter((sale) => {
          return !salesIds.includes(sale._id);
        }),
      };
    });
  } catch (err) {}
};

// GET PAGINATED FINISHED SALES //////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
interface GetPaginatedFinishedSalesProps extends FetchProps {
  setLoadedData: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
  setShowLoadMore: React.Dispatch<React.SetStateAction<boolean>>;
  multiplier: number;
  hidration: boolean;
  setFirstLoad?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const getPaginatedFinishedSales = async (
  props: GetPaginatedFinishedSalesProps
) => {
  const {
    sendRequest,
    auth,
    setLoadedData,
    setShowLoadMore,
    multiplier,
    hidration,
    setFirstLoad,
  } = props;

  try {
    const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/sales/finished/paginated/ALL/${multiplier}`;
    const responseData: {
      kanban: KanbanData;
      items: SalePopCustData[];
      hasMoreItems: boolean;
    } = await sendRequest(apiUrl, "GET", null, {
      Authorization: "Bearer " + auth.token,
    });

    setShowLoadMore(responseData.hasMoreItems);
    setLoadedData((prevValues) => {
      if (!!prevValues && prevValues?.items?.length > 0 && !hidration) {
        return {
          kanban: responseData.kanban,
          items: [...prevValues?.items, ...responseData.items],
        };
      } else {
        return {
          kanban: responseData.kanban,
          items: responseData.items,
        };
      }
    });
    if (setFirstLoad) {
      setFirstLoad(true);
    }
  } catch (err) {}
};

// GET FILTERED FINISHED SALES /////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
interface GetFilteredFinishedSalesProps extends FetchProps {
  setLoadedData: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
  setFiltered: React.Dispatch<React.SetStateAction<boolean>>;
  status: "CLOSED" | "LOST" | "ALL";
  searchValue: string;
  filter1Active: boolean;
  filter1Value: {
    timePeriodEarlierSelected: string;
    timePeriodOlderSelected: string;
  };
  setShowLoadMore: React.Dispatch<React.SetStateAction<boolean>>;
  multiplier: number;
  fetchId: string;
  setMultiplier?: React.Dispatch<React.SetStateAction<number>>;
  type: "SALES" | "PROJECT" | "INSTALLATION" | "AFTER_SALES";
}

export const getFilteredFinishedSales = async (
  props: GetFilteredFinishedSalesProps
) => {
  const {
    auth,
    sendRequest,
    setLoadedData,
    setFiltered,
    searchValue,
    status,
    filter1Active,
    filter1Value,
    setShowLoadMore,
    multiplier,
    setMultiplier,
    fetchId,
    type,
  } = props;

  const fetchType =
    fetchId === auth.userId || auth.signupPlan !== "ENTERPRISE"
      ? "USER"
      : fetchId === auth.managerId
      ? "MANAGER"
      : "VENDOR";

  try {
    const responseData: {
      kanban: KanbanData;
      items: SalePopCustData[];
      hasMoreItems: boolean;
    } = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/sales/finished/filtered/${multiplier}`,
      "POST",
      JSON.stringify({
        status,
        fetchType,
        fetchId,
        searchValue,
        filter1Active,
        filter1Value,
        type,
      }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
      false,
      "sales"
    );

    setLoadedData((prevValues) => {
      if (!!prevValues && prevValues?.items?.length > 0 && multiplier > 0) {
        return {
          kanban: responseData.kanban,
          items: [...prevValues?.items, ...(responseData?.items || [])],
        };
      } else {
        return {
          kanban: responseData.kanban,
          items: responseData?.items || [],
        };
      }
    });
    setFiltered(true);
    setShowLoadMore(responseData.hasMoreItems);
    if (setMultiplier) {
      setMultiplier(0);
    }
  } catch (err) {
    console.log(err);
  }
};

// GET KANBAN SALES NAMES //////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
interface GetKanbanSalesNamesProps extends FetchProps {
  setLoadedSales: React.Dispatch<React.SetStateAction<SaleData[]>>;
  formState: FormHookDispState;
  type: "SALES" | "PROJECT" | "INSTALLATION" | "AFTER_SALES";
}

export const getKanbanSalesNames = async (props: GetKanbanSalesNamesProps) => {
  const { sendRequest, auth, setLoadedSales, formState, type } = props;

  try {
    const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/sales/kanban/names/${auth.userId}/${type}`;
    const responseData: { sales: SaleData[] } = await sendRequest(
      apiUrl,
      "POST",
      JSON.stringify({
        searchValue: formState.inputs?.saleName?.value?.toString() || "",
      }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      }
    );

    setLoadedSales(responseData.sales);
  } catch (err) {}
};

// GET KANBAN COLUMN TOTALS ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
interface GetKanbanColumnTotalsProps extends FetchProps {
  setTotals: React.Dispatch<
    React.SetStateAction<{
      totalValue: number;
      totalItems: number;
    }>
  >;
  fetchId: string;
  columnId: string;
  type: "SALES" | "PROJECT" | "INSTALLATION" | "AFTER_SALES";
  searchValue: string;
  filter1Active: boolean;
  filter1Value?: TimePeriodData;
}

export const getKanbanColumnTotals = async (
  props: GetKanbanColumnTotalsProps
) => {
  const {
    sendRequest,
    auth,
    type,
    setTotals,
    fetchId,
    columnId,
    searchValue,
    filter1Active,
    filter1Value,
  } = props;

  let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/kanbans/single/column-totals/${fetchId}/${columnId}`;

  try {
    const responseData: {
      totalValue: number;
      totalItems: number;
    } = await sendRequest(
      apiUrl,
      "POST",
      JSON.stringify({
        searchValue,
        filter1Active,
        filter1Value,
        type,
      }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      }
    );

    setTotals(responseData);
  } catch (err) {}
};

// POPULATE KANBAN ITEM ////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
interface PopulateKanbanItemProps extends FetchProps {
  itemId: string;
  type: "SALES" | "PROJECT" | "INSTALLATION" | "AFTER_SALES";
  setKanbanData: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
}

export const populateKanbanItem = async (props: PopulateKanbanItemProps) => {
  const { sendRequest, auth, type, setKanbanData, itemId } = props;

  let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/kanbans/single/item/${itemId}`;

  try {
    const responseData: { populatedItem: KanbanFilledData["items"][0] } =
      await sendRequest(
        apiUrl,
        "POST",
        JSON.stringify({
          type,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );

    setKanbanData((prevValues) => {
      if (
        prevValues?.items
          ?.map((it) => {
            return it?._id;
          })
          ?.includes(itemId)
      ) {
        return {
          kanban: prevValues.kanban,
          items: [
            ...prevValues.items.map((item) => {
              if (item._id === itemId) {
                item = responseData.populatedItem;
              }

              return item;
            }),
          ],
        };
      } else {
        return {
          kanban: prevValues.kanban,
          items: [...prevValues.items, responseData.populatedItem],
        };
      }
    });
  } catch (err) {}
};
