export const getSaleTypeClientData = (
  type: "SALES" | "PROJECT" | "INSTALLATION" | "AFTER_SALES",
  userId: string
) => {
  const typeName =
    type === "SALES"
      ? "Venda"
      : type === "PROJECT"
      ? "Projeto"
      : type === "INSTALLATION"
      ? "Obra"
      : "Atividade";

  const redirectUrl =
    type === "SALES"
      ? `/vendas/funil/${userId}`
      : type === "PROJECT"
      ? `/projetos/quadro/${userId}`
      : type === "INSTALLATION"
      ? `/instalacoes/quadro/${userId}`
      : `/pos-venda/quadro/${userId}`;

  const registerUrl =
    type === "SALES"
      ? "/vendas/registro"
      : type === "PROJECT"
      ? `/projetos/registro`
      : type === "INSTALLATION"
      ? `/instalacoes/registro`
      : `/pos-venda/registro`;

  return { typeName, redirectUrl, registerUrl };
};
