export const getSaleStatusNameFromId = (
  id: "CLOSED" | "LOST" | "IN_PROGRESS"
) => {
  switch (id) {
    case "CLOSED":
      return "Venda Ganha";

    case "LOST":
      return "Venda Perdida";

    default:
      return "";
  }
};
