import { useState, useEffect } from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import IconButton from "@material-ui/core/IconButton";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import ListAltRoundedIcon from "@material-ui/icons/ListAltRounded";
import DescriptionRoundedIcon from "@material-ui/icons/DescriptionRounded";
import RateReviewRoundedIcon from "@material-ui/icons/RateReviewRounded";
import InsertDriveFileRoundedIcon from "@material-ui/icons/InsertDriveFileRounded";
import HistoryRoundedIcon from "@material-ui/icons/HistoryRounded";

import { SaleDashGenData } from "./SaleDashGenData";
import { SaleDashProposals } from "./SaleDashProposals";
import { SaleDashNotes } from "./SaleDashNotes";
import { SaleDashContracts } from "./SaleDashContracts";
import { SaleDashFiles } from "./SaleDashFiles";
import { SaleDashHistory } from "./SaleDashHistory";
import { useHttpClient } from "../../shared/hooks/httpHook";
import { ModalError } from "../../shared/components/UIElements/ModalError";
import { LoadingSpinnerOverlayRegular } from "../../shared/components/UIElements/LoadingSpinnerOverlayRegular";
import {
  AuthContextProps,
  KanbanFilledData,
  NoteData,
  SettingsContextProps,
  NotePopCustData,
} from "../../shared/data/types";

import "./SaleDash.scss";

interface SaleDashProps {
  type: "SALES" | "PROJECT" | "INSTALLATION" | "AFTER_SALES";
  active: boolean;
  closeHandler: () => void;
  kanbanData: KanbanFilledData;
  loadedItem: KanbanFilledData["items"][0];
  activeNote?: string;
  auth: AuthContextProps;
  settingsCtx: SettingsContextProps;
  setLoadedData: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
  setScheduleData?: React.Dispatch<React.SetStateAction<NotePopCustData[]>>;
  setActiveItem: React.Dispatch<React.SetStateAction<string>>;
  fetchId: string;
  setUpdateColumnsTotals?: React.Dispatch<React.SetStateAction<string[]>>;
}

export const SaleDash = (props: SaleDashProps) => {
  const {
    active,
    closeHandler,
    loadedItem,
    auth,
    setLoadedData,
    setActiveItem,
    activeNote = "",
    fetchId,
    kanbanData,
    settingsCtx,
    type,
    setScheduleData,
    setUpdateColumnsTotals,
  } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [isIsSmallWidth, setIsSmallWidth] = useState<boolean>(false);
  const [tab, setTab] = useState<number>(0);
  const [memory, setMemory] = useState<KanbanFilledData["items"][0]>(null);
  const canAccessPropList =
    settingsCtx.individualPermissions
      ?.filter((ip) => {
        return ip.proposalsList;
      })
      ?.map((ip) => {
        return ip?.user;
      })
      .includes(auth.userId) || auth.type !== "VENDOR";

  useEffect(() => {
    if (!!loadedItem) {
      setMemory(loadedItem);
    }
  }, [loadedItem]);

  const resizeHandler = () => {
    if (window.innerWidth < 600) {
      setIsSmallWidth(true);
    } else {
      setIsSmallWidth(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);
    resizeHandler();

    var timer = setTimeout(() => {
      resizeHandler();
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const changeTabHandler = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };

  return (
    <div className={`sale-dash ${active ? "sale-dash--active" : ""}`}>
      <ModalError error={error} onClear={clearError} />
      <ClickAwayListener
        mouseEvent="onMouseDown"
        onClickAway={() => {
          if (active) {
            closeHandler();
          }
        }}
      >
        <div className="sale-dash__content">
          {isLoading && <LoadingSpinnerOverlayRegular image />}
          <div className="sale-dash__controllers">
            <IconButton
              size="small"
              title="Opções"
              className={`table__item-action`}
              onClick={() => {
                if (active) {
                  closeHandler();
                }
              }}
            >
              <CloseRoundedIcon style={{ fontSize: "22px" }} />
            </IconButton>
          </div>
          <div className="sale-dash__col-left">
            <SaleDashGenData
              auth={auth}
              fetchId={fetchId}
              memory={memory}
              setLoadedData={setLoadedData}
              sendRequest={sendRequest}
              kanbanData={kanbanData}
              setActiveItem={setActiveItem}
              settingsCtx={settingsCtx}
              type={type}
              setUpdateColumnsTotals={setUpdateColumnsTotals}
            />
          </div>
          <div className="sale-dash__col-right">
            <div className="sale-dash__col-right-content">
              {!isIsSmallWidth && (
                <Tabs
                  className="sale-dash__col-right-tabs"
                  value={tab}
                  onChange={changeTabHandler}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                >
                  <Tab style={{ minWidth: "10px" }} label="Notas" />
                  <Tab
                    style={{ minWidth: "10px" }}
                    label="Propostas"
                    disabled={!canAccessPropList || type === "AFTER_SALES"}
                  />
                  <Tab
                    style={{ minWidth: "10px" }}
                    label="Contratos"
                    disabled={
                      auth.signupPlan !== "ENTERPRISE" || type === "AFTER_SALES"
                    }
                  />
                  <Tab
                    style={{ minWidth: "10px" }}
                    label="Arquivos"
                    disabled={auth.signupPlan !== "ENTERPRISE"}
                  />
                  <Tab
                    style={{ minWidth: "10px" }}
                    label="Histórico"
                    disabled={auth.type === "VENDOR"}
                  />
                </Tabs>
              )}
              {isIsSmallWidth && (
                <Tabs
                  className="sale-dash__col-right-tabs"
                  value={tab}
                  onChange={changeTabHandler}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                >
                  <Tab
                    style={{ minWidth: "10px" }}
                    icon={<RateReviewRoundedIcon />}
                  />
                  <Tab
                    style={{ minWidth: "10px" }}
                    disabled={!canAccessPropList || type === "AFTER_SALES"}
                    icon={<ListAltRoundedIcon />}
                  />
                  <Tab
                    style={{ minWidth: "10px" }}
                    disabled={
                      auth.signupPlan !== "ENTERPRISE" || type === "AFTER_SALES"
                    }
                    icon={<DescriptionRoundedIcon />}
                  />
                  <Tab
                    style={{ minWidth: "10px" }}
                    disabled={auth.signupPlan !== "ENTERPRISE"}
                    icon={<InsertDriveFileRoundedIcon />}
                  />
                  <Tab
                    style={{ minWidth: "10px" }}
                    disabled={auth.type === "VENDOR"}
                    icon={<HistoryRoundedIcon />}
                  />
                </Tabs>
              )}
              {tab === 0 && (
                <SaleDashNotes
                  settingsCtx={settingsCtx}
                  auth={auth}
                  type={type}
                  notes={memory?.notes as NoteData[]}
                  itemId={memory?._id}
                  customerId={memory?.customer?.id}
                  activeNote={activeNote}
                  setLoadedData={setLoadedData}
                  setScheduleData={setScheduleData}
                />
              )}
              {tab === 1 && canAccessPropList && (
                <SaleDashProposals
                  memory={memory}
                  auth={auth}
                  itemId={memory?._id}
                />
              )}
              {tab === 2 && (
                <SaleDashContracts
                  contracts={memory?.contracts}
                  auth={auth}
                  itemId={memory?._id}
                  item={memory}
                  setKanbanData={setLoadedData}
                />
              )}
              {tab === 3 && <SaleDashFiles cid={memory?.customer?.id} />}
              {tab === 4 && (
                <SaleDashHistory
                  auth={auth}
                  customerId={memory?.customer?.id}
                />
              )}
            </div>
          </div>
        </div>
      </ClickAwayListener>
    </div>
  );
};
