import React, { useState, useContext, Fragment } from "react";
import { NavLink } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";
import Button from "@material-ui/core/Button";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { useHistory } from "react-router-dom";

import { MenuCustomCenterBottom } from "../MUIThemed/MenuCustomCenterBottom";
import { ModalError } from "../../components/UIElements/ModalError";
import { useHttpClient } from "../../hooks/httpHook";
import { MenuItemCustomBlueFocusBackground } from "../MUIThemed/MenuItemCustomBlueFocusBackground";
import { AuthContext } from "../../context/authContext";
import { FetchProps } from "../../../customer/api/customersAPI";

interface DesktopNavMenuProps {
  menuTitle: string;
  iconBtn?: boolean;
  textItem1: string;
  textItem2?: string;
  textItem3?: string;
  textItem4?: string;
  textItem5?: string;
  textItem6?: string;
  link1: string;
  link2?: string;
  link3?: string;
  link4?: string;
  link5?: string;
  link6?: string;
  function3?: (props: FetchProps) => Promise<void>;
  icon1: JSX.Element;
  icon2?: JSX.Element;
  icon3?: JSX.Element;
  icon4?: JSX.Element;
  icon5?: JSX.Element;
  icon6?: JSX.Element;
}

export const DesktopNavMenu = (props: DesktopNavMenuProps) => {
  const {
    menuTitle,
    iconBtn,
    textItem1,
    textItem2,
    textItem3,
    textItem4,
    textItem5,
    textItem6,
    link1,
    link2,
    link3,
    link4,
    link5,
    link6,
    function3,
    icon1,
    icon2,
    icon3,
    icon4,
    icon5,
    icon6,
  } = props;
  const auth = useContext(AuthContext);
  const router = useHistory();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { error, sendRequest, clearError, isLoading } = useHttpClient();

  const clickHandler = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeHandler = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <ModalError error={error} onClear={clearError} />
      <div>
        {!iconBtn && (
          <Button color="inherit" onClick={clickHandler}>
            {menuTitle}
          </Button>
        )}
        {iconBtn && (
          <IconButton title={menuTitle} color="inherit" onClick={clickHandler}>
            <AccountCircleRoundedIcon />
          </IconButton>
        )}
        <MenuCustomCenterBottom
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={closeHandler}
        >
          <NavLink to={link1}>
            <MenuItemCustomBlueFocusBackground>
              <ListItemIcon>{icon1}</ListItemIcon>
              <ListItemText primary={textItem1} />
            </MenuItemCustomBlueFocusBackground>
          </NavLink>
          {link2 && (
            <NavLink to={link2}>
              <MenuItemCustomBlueFocusBackground>
                <ListItemIcon>{icon2}</ListItemIcon>
                <ListItemText primary={textItem2} />
              </MenuItemCustomBlueFocusBackground>
            </NavLink>
          )}
          {function3 && auth.type !== "VENDOR" && (
            <div
              onClick={() => {
                if (!isLoading) {
                  function3({ auth, sendRequest });
                }
              }}
            >
              <MenuItemCustomBlueFocusBackground disabled={isLoading}>
                <ListItemIcon>{icon3}</ListItemIcon>
                <ListItemText primary={textItem3} />
              </MenuItemCustomBlueFocusBackground>
            </div>
          )}
          {link3 && (
            <NavLink to={link3}>
              <MenuItemCustomBlueFocusBackground>
                <ListItemIcon>{icon3}</ListItemIcon>
                <ListItemText primary={textItem3} />
              </MenuItemCustomBlueFocusBackground>
            </NavLink>
          )}
          {link4 &&
            (textItem4 !== "Upload de Clientes" || auth.type !== "VENDOR") &&
            (!link4.includes("/configuracoes") ||
              (link4.includes("/configuracoes") && auth.type !== "VENDOR")) && (
              <NavLink to={link4}>
                <MenuItemCustomBlueFocusBackground>
                  <ListItemIcon>{icon4}</ListItemIcon>
                  <ListItemText primary={textItem4} />
                </MenuItemCustomBlueFocusBackground>
              </NavLink>
            )}
          {link5 && (
            <NavLink to={link5}>
              <MenuItemCustomBlueFocusBackground>
                <ListItemIcon>{icon5}</ListItemIcon>
                <ListItemText primary={textItem5} />
              </MenuItemCustomBlueFocusBackground>
            </NavLink>
          )}
          {link6 && link6 === "user-loggout" && (
            <MenuItemCustomBlueFocusBackground
              onClick={() => {
                auth.logout();
                router.push("/");
              }}
            >
              <ListItemIcon>{icon6}</ListItemIcon>
              <ListItemText primary={textItem6} />
            </MenuItemCustomBlueFocusBackground>
          )}
          {link6 && link6 !== "user-loggout" && (
            <NavLink to={link6}>
              <MenuItemCustomBlueFocusBackground>
                <ListItemIcon>{icon6}</ListItemIcon>
                <ListItemText primary={textItem6} />
              </MenuItemCustomBlueFocusBackground>
            </NavLink>
          )}
        </MenuCustomCenterBottom>
      </div>
    </Fragment>
  );
};
