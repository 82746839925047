import { makeStyles } from "@material-ui/core/styles";

export const ModalErrorStyles = makeStyles(() => ({
  errorModalTitle: {
    color: "#f50057",
  },
  errorModalContent: {
    color: "#424242",
  },
}));
