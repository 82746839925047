import { useState, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import Button from "@material-ui/core/Button";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";

import { ModalError } from "../../shared/components/UIElements/ModalError";
import { ModalPurchaseModule } from "../../shared/components/UIElements/ModalPurchaseModule";
import { ModalSuccess } from "../../shared/components/UIElements/ModalSuccess";
import { ModalConfirm } from "../../shared/components/UIElements/ModalConfirm";
import { InputPrefixOutlinedInt } from "../../shared/components/FormElements/InputPrefixOutlinedInt";
import { InputDefined } from "../../shared/components/FormElements/InputDefined";
import { useForm } from "../../shared/hooks/formHook";
import { dateFormat } from "../../shared/util/dateFormat";
import { getDaysFromDate } from "../../shared/util/getDaysFromDate";
import { PopoverAddSignaturePerson } from "./PopoverAddSignaturePerson";
import { SaleDashSignaturePerson } from "./SaleDashSignaturePerson";
import { LoadingSpinnerOverlayRegular } from "../../shared/components/UIElements/LoadingSpinnerOverlayRegular";
import {
  getSinature,
  createSignature,
  signDocument,
  loadDocsFromClicksign,
  getAvailableSignatures,
} from "../api/signatureAPI";
import {
  VALIDATOR_REQUIRE,
  VALIDATOR_INT,
  VALIDATOR_MIN,
} from "../../shared/util/validators";
import { useHttpClient } from "../../shared/hooks/httpHook";
import {
  AuthContextProps,
  KanbanFilledData,
  SignatureData,
} from "../../shared/data/types";

import "./SaleDashSignature.scss";

interface SaleDashSignatureProps {
  auth: AuthContextProps;
  saleId: string;
  loadedContract: KanbanFilledData["items"][0]["contracts"][0];
  loadedSale: KanbanFilledData["items"][0];
}

export const SaleDashSignature = (props: SaleDashSignatureProps) => {
  const { loadedContract, auth, saleId, loadedSale } = props;
  const { error, sendRequest, clearError, isLoading, success, clearSuccess } =
    useHttpClient();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>(null);
  const [loadedSignature, setLoadedSignature] = useState<SignatureData>(null);
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [showPurchaseModal, setShowPurchaseModule] = useState<boolean>(false);
  const [loadedDownloadUrls, setLoadedDownloadUrls] = useState<{
    original_file_url: string;
    signed_file_url: string | undefined;
    ziped_file_url: string;
  }>(null);
  const [personsWhoSigned, setPersonsWhoSigned] = useState<string[]>([]);
  const [singaturesTotals, setSingaturesTotals] = useState<{
    totalSignatures: number;
    usedSignatures: number;
    validityDate: string;
  }>({ totalSignatures: 0, usedSignatures: 0, validityDate: "" });
  const [validityDate, setValidityDate] = useState<string>(dateFormat(15));
  const [confirm, setConfirm] = useState<boolean>(false);
  const { formState, inputHandler, setFormData, reportInvalid } = useForm(
    {},
    false
  );

  useEffect(() => {
    getAvailableSignatures({ sendRequest, auth, setSingaturesTotals });
  }, []);

  useEffect(() => {
    getSinature({
      sendRequest,
      auth,
      setLoadedSignature,
      saleId,
      saleContractId: loadedContract?._id,
    }).then((data) => {
      if (!data) {
        createSignature({
          sendRequest,
          auth,
          setLoadedSignature,
          saleId,
          saleContractId: loadedContract?._id,
        });
      }
    });
  }, [loadedContract?._id, saleId]);

  useEffect(() => {
    if (!!loadedSignature?.clicksignKey) {
      loadDocsFromClicksign({
        sendRequest,
        auth,
        saleId,
        saleContractId: loadedContract?._id,
        setLoadedDownloadUrls,
        setPersonsWhoSigned,
      });
    }
  }, [loadedSignature?.clicksignKey]);

  const loadFile = (
    url: string,
    callback: (err: Error, data: string) => void
  ) => {
    PizZipUtils.getBinaryContent(url, callback);
  };

  const toBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });
  };

  const generateBase64FileUrl = (err: Error, data: string) => {
    if (err) {
      setIsGenerating(false);
      console.log(err);
      alert("Erro ao tentar carregar o arquivo.");
      return;
    }
    const zip = new PizZip(data);
    const doc = new Docxtemplater(zip, {
      paragraphLoop: true,
      linebreaks: true,
    });

    doc.render();
    const blob = doc.getZip().generate({
      type: "blob",
      mimeType:
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    }); //Output the document using Data-URI
    const file = new File([blob], "contrato.docx", {
      type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    });

    toBase64(file).then((data: string) => {
      signDocument({
        sendRequest,
        auth,
        saleId,
        saleContractId: loadedContract?._id,
        setLoadedSignature,
        base64File: data,
        formState,
      });
    });

    setIsGenerating(false);
    setAnchorEl(null);
  };

  const sendDocumentSigningHandler = () => {
    setIsGenerating(true);

    loadFile(
      `${process.env.REACT_APP_ASSET_URL}/${loadedContract.fileUrl}`,
      generateBase64FileUrl
    );
  };

  const loadDocsFromClicksignHandler = (type: "SIGNED" | "ORIGINAL") => {
    if (!loadedDownloadUrls) {
      loadDocsFromClicksign({
        sendRequest,
        auth,
        saleId,
        saleContractId: loadedContract?._id,
        setLoadedDownloadUrls,
        setPersonsWhoSigned,
      }).then((data) => {
        window.open(
          type === "SIGNED"
            ? data.signed_file_url || data.original_file_url
            : data.original_file_url,
          "_blank"
        );
      });
    } else {
      window.open(
        type === "SIGNED"
          ? loadedDownloadUrls.signed_file_url ||
              loadedDownloadUrls.original_file_url
          : loadedDownloadUrls.original_file_url,
        "_blank"
      );
    }
  };

  const closeConfirmHandler = () => {
    setConfirm(false);
  };

  const confirmHandler = () => {
    setConfirm(false);
    if (auth.activeModules?.includes("Assinatura Digital")) {
      sendDocumentSigningHandler();
    } else {
      setShowPurchaseModule(true);
    }
  };

  useEffect(() => {
    if (+formState?.inputs?.validity?.value > 0) {
      setValidityDate(dateFormat(Number(formState?.inputs?.validity?.value)));
    }
  }, [formState?.inputs?.validity?.value]);

  return (
    <div className="signature">
      {(!loadedSignature || isLoading || isGenerating) && (
        <LoadingSpinnerOverlayRegular image />
      )}
      {loadedSignature && (
        <PopoverAddSignaturePerson
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          auth={auth}
          setLoadedSignature={setLoadedSignature}
          saleId={saleId}
          loadedContract={loadedContract}
        />
      )}
      <ModalSuccess
        success={success}
        onClear={clearSuccess}
        message="Assinatura digital gerada com sucesso! Notifique os signatários via E-mail, WhatsApp ou SMS para que realizem a assinatura. Você também pode copiar o link de assinatura e enviá-lo diretamente aos signatários."
        dontPush
        reset={() => {}}
      />
      <ModalConfirm
        open={confirm}
        closeHandler={closeConfirmHandler}
        confirmHandler={confirmHandler}
        message="Deseja gerar o documento da assinatura digital? Antes de gerar o documento certifique-se de que todos os dados dos signatários estejam corretos! Não será possível alterar dados de signatários após gerado o documento da assinatura digital."
      />
      <ModalPurchaseModule
        open={showPurchaseModal}
        setOpen={setShowPurchaseModule}
        title="LIBERE A ASSINATURA DIGITAL"
        content="Com o módulo de assinatura digital você conseguirá realizar a assinatura de contratos com validade jurídica de forma rápida e simples. Chame nossa equipe no WhatsApp e descubra como liberar esse módulo em sua conta!"
      />
      <ModalError error={error} onClear={clearError} />
      <h4 className="signature__title">Assinatura Digital</h4>
      <div className="signature__persons">
        <p className="signature__persons-subtitle">
          Ass. Disponíveis:{" "}
          {singaturesTotals.totalSignatures - singaturesTotals.usedSignatures}
        </p>
        <p className="signature__persons-subtitle">
          Ass. Contratadas: {singaturesTotals.totalSignatures}
        </p>
        <p className="signature__persons-subtitle">
          Expira em: {singaturesTotals.validityDate}
        </p>
        <div className="signature__persons-heading">
          <p className="signature__persons-title">Signatários</p>
          <div className="signature__persons-add-btn">
            <IconButton
              disabled={!loadedSignature || !!loadedSignature.clicksignKey}
              size="small"
              title="Adicionar Signatário"
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
              }}
            >
              <AddRoundedIcon />
            </IconButton>
          </div>
        </div>
        <div className="signature__persons-list">
          {loadedSignature?.persons?.map((person, i) => {
            return (
              <SaleDashSignaturePerson
                auth={auth}
                saleId={saleId}
                loadedContract={loadedContract}
                loadedSignature={loadedSignature}
                person={person}
                setLoadedSignature={setLoadedSignature}
                loadedDownloadUrls={loadedDownloadUrls}
                personsWhoSigned={personsWhoSigned}
                key={`signature-person-${i}`}
              />
            );
          })}
        </div>
      </div>
      {(!loadedSignature?.clicksignKey ||
        loadedSignature?.clicksignKey === "") && (
        <form className="signature__validity">
          <div className="form-inputs-grid-1fr-1fr">
            <InputPrefixOutlinedInt
              id="validity"
              type="text"
              label="Validade"
              variant="outlined"
              prefix="Dias"
              onInput={inputHandler}
              validators={[
                VALIDATOR_REQUIRE(),
                VALIDATOR_INT(),
                VALIDATOR_MIN(1),
              ]}
              initialValue={
                getDaysFromDate(loadedSale?.validityDate)?.toString() || "15"
              }
              initialValid={true}
              helperText="Validade da proposta em dias. Ex: 15."
              forceError={
                formState.inputs?.validity &&
                formState?.inputs?.validity?.forceError
              }
            />
            <InputDefined
              id="validityDate"
              type="text"
              label="Data Validade"
              onInput={inputHandler}
              disabled={true}
              variant="filled"
              validators={[VALIDATOR_REQUIRE()]}
              initialValue={validityDate}
            />
          </div>
        </form>
      )}
      <div className="signature__action">
        {(!loadedSignature?.clicksignKey ||
          loadedSignature?.clicksignKey === "") && (
          <Button
            variant="contained"
            color="primary"
            disabled={
              loadedSignature?.persons?.length < 1 || auth.type !== "MANAGER"
            }
            onClick={() => {
              if (auth.activeModules?.includes("Assinatura Digital")) {
                setConfirm(true);
                setAnchorEl(null);
              } else {
                setShowPurchaseModule(true);
              }
            }}
          >
            GERAR ASSINATURA DIGITAL
          </Button>
        )}
        {!!loadedSignature?.clicksignKey && (
          <div className="form-inputs-grid-1fr-1fr form-inputs-grid-responsive">
            <Button
              variant="contained"
              color="primary"
              disabled={!loadedDownloadUrls?.signed_file_url}
              onClick={() => {
                loadDocsFromClicksignHandler("SIGNED");
                setAnchorEl(null);
              }}
            >
              CONTRATO ASSINADO
            </Button>
            <Button
              variant="outlined"
              color="primary"
              disabled={loadedSignature?.persons?.length < 1}
              onClick={() => {
                loadDocsFromClicksignHandler("ORIGINAL");
                setAnchorEl(null);
              }}
            >
              CONTRATO ORIGINAL
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
