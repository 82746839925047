import { useState, useEffect, Fragment } from "react";

import { PropDashUcItem } from "./PropDashUcItem";
import { AuthContextProps, ProposalData } from "../../shared/data/types";

import "./PropDash.scss";

interface PropDashUcsProps {
  auth: AuthContextProps;
  memory: ProposalData;
}

export const PropDashUcs = (props: PropDashUcsProps) => {
  const { auth, memory } = props;

  return (
    <Fragment>
      {memory?.energyBillData?.length === 0 && (
        <p className="cust-dash__col-right-content-empty">
          Esta proposta não possui unidades consumidoras cadastradas
        </p>
      )}
      <div className="cust-dash__history">
        {memory?.energyBillData?.length > 0 &&
          memory?.energyBillData?.map((item, i) => {
            return (
              <PropDashUcItem
                auth={auth}
                loadedUc={item}
                losses={memory.estimateSystemData?.losses || 0}
                isAGroup={memory?.tariffModality === "A"}
                memory={memory}
                index={i}
                key={`uc-${i}`}
              />
            );
          })}
      </div>
    </Fragment>
  );
};
