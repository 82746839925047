import { Fragment } from "react";

import { PropDashPVModItem } from "./PropDashPVModItem";
import { PropDashInvItem } from "./PropDashInvItem";
import { PropDashAddItem } from "./PropDashAddItem";
import { AuthContextProps, ProposalData } from "../../shared/data/types";

import "./PropDash.scss";

interface PropDashKitProps {
  auth: AuthContextProps;
  memory: ProposalData;
}

export const PropDashKit = (props: PropDashKitProps) => {
  const { auth, memory } = props;

  return (
    <Fragment>
      {memory?.pvModuleData?.length === 0 && (
        <p className="cust-dash__col-right-content-empty">
          Esta proposta não possui kit cadastrado
        </p>
      )}
      <div className="cust-dash__history">
        {memory?.pvModuleData?.length > 0 &&
          memory?.pvModuleData?.map((item, i) => {
            return (
              <PropDashPVModItem
                auth={auth}
                loadedPvMod={item}
                index={i}
                key={`pv-mod-${i}`}
              />
            );
          })}
        {memory?.inverterData?.length > 0 &&
          memory?.inverterData?.map((item, i) => {
            return (
              <PropDashInvItem
                auth={auth}
                loadedInv={item}
                index={i}
                key={`inv-${i}`}
              />
            );
          })}
        {memory?.additionalItems?.length > 0 &&
          memory?.additionalItems?.map((item, i) => {
            return (
              <PropDashAddItem
                auth={auth}
                loadedAddItem={item}
                index={i}
                key={`add-item-${i}`}
              />
            );
          })}
      </div>
    </Fragment>
  );
};
