import Popover from "@material-ui/core/Popover";
import React, { Fragment, useContext } from "react";
import Button from "@material-ui/core/Button";

import { useForm } from "../../shared/hooks/formHook";
import { Input } from "../../shared/components/FormElements/Input";
import { InputSwitch } from "../../shared/components/FormElements/InputSwitch";
import { InputCalendar } from "../../shared/components/FormElements/InputCalendar";
import { InputTime } from "../../shared/components/FormElements/InputTime";
import { InputSelect } from "../../shared/components/FormElements/InputSelect";
import { SettingsContext } from "../../shared/context/settingsContext";
import { dateFormatFromISOString } from "../../shared/util/dateFormatFromISOString";
import { dateFormatToISOString } from "../../shared/util/dateFormatToISOString";
import { VALIDATOR_REQUIRE } from "../../shared/util/validators";
import { newNote, editNote } from "../api/salesFunnelAPI";
import {
  AuthContextProps,
  KanbanFilledData,
  NoteData,
  NotePopCustData,
  SettingsContextProps,
} from "../../shared/data/types";

import { PopoverFormStyles } from "./PopoverFormStyles";

interface PopoverFormAddOrEditNoteProps {
  id: string;
  nid?: string;
  initalContent?: string;
  initialtype?: string;
  initialWarning?: boolean;
  initWarningTimeStart?: string;
  initWarningTimeEnd?: string;
  initialWarningDate?: string;
  anchorEl: HTMLButtonElement | HTMLLIElement | null;
  setAnchorEl: React.Dispatch<
    React.SetStateAction<HTMLButtonElement | HTMLLIElement>
  >;
  auth: AuthContextProps;
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean
  ) => Promise<any>;
  setKanbanData?: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
  apiCall: "create" | "edit";
  scheduleListElement?: boolean;
  setLoadedData?: React.Dispatch<
    React.SetStateAction<NoteData[] | NotePopCustData[]>
  >;
  anchorLeft?: boolean;
}

export const PopoverFormAddOrEditNote = (
  props: PopoverFormAddOrEditNoteProps
) => {
  const {
    id,
    nid,
    anchorEl,
    setAnchorEl,
    auth,
    sendRequest,
    setKanbanData,
    initalContent,
    initialtype,
    initialWarning,
    initialWarningDate,
    initWarningTimeStart,
    initWarningTimeEnd,
    apiCall,
    scheduleListElement,
    setLoadedData,
    anchorLeft,
  } = props;
  const open = Boolean(anchorEl);
  const classes = PopoverFormStyles();
  const today = new Date();
  const settingsCtx = useContext(SettingsContext);
  const { formState, inputHandler } = useForm(
    {
      noteText: {
        value: initalContent || "",
        isValid: false,
      },
      warning: {
        value: initialWarning || false,
        isValid: true,
      },
      warningDate: {
        value: !!initialWarningDate
          ? dateFormatToISOString(initialWarningDate)
          : new Date().toISOString(),
        isValid: true,
      },
      warningTimeStart: {
        value: !!initWarningTimeStart
          ? initWarningTimeStart
          : new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate(),
              0,
              0,
              0,
              0
            ).toISOString(),
        isValid: true,
      },
      warningTimeEnd: {
        value: !!initWarningTimeEnd
          ? initWarningTimeEnd
          : new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate(),
              0,
              0,
              0,
              0
            ).toISOString(),
        isValid: true,
      },
    },
    false
  );

  return (
    <Fragment>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        style={{ zIndex: 501 }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: !anchorLeft ? "center" : "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: !anchorLeft ? "center" : "left",
        }}
      >
        <form style={{ zIndex: 502 }} className={classes.formRoot}>
          <Input
            id="noteText"
            type="text"
            label="Nota"
            variant="outlined"
            validators={[VALIDATOR_REQUIRE()]}
            helperText="Insira um conteúdo válido."
            onInput={inputHandler}
            initialValue={!!initalContent ? initalContent : ""}
            initialValid={!!initalContent}
            multiline
            rows={3}
            isMessage
          />
          <InputSelect
            id="type"
            label="Tipo de Nota"
            variant="outlined"
            onInput={inputHandler}
            options={settingsCtx?.notesTypes || []}
            initialValue={initialtype || ""}
          />
          <div className="form-inputs-grid-1fr-1fr">
            <InputSwitch
              title="Agendar Nota?"
              id="warning"
              label="Agenda?"
              initialValue={
                typeof initialWarning === "boolean" ? initialWarning : true
              }
              color="primary"
              onInput={inputHandler}
            />
            <InputCalendar
              id="warningDate"
              label="Data"
              format="dd/MM/yyyy"
              disabled={!formState.inputs?.warning?.value as boolean}
              initialvalue={
                !!initialWarningDate
                  ? dateFormatToISOString(initialWarningDate)
                  : new Date().toISOString()
              }
              onInput={inputHandler}
            />
          </div>
          <div className="form-inputs-grid-1fr-1fr">
            <InputTime
              id="warningTimeStart"
              label="Início"
              disabled={!formState.inputs?.warning?.value as boolean}
              initialvalue={initWarningTimeStart}
              onInput={inputHandler}
            />
            <InputTime
              id="warningTimeEnd"
              label="Fim"
              disabled={!formState.inputs?.warning?.value as boolean}
              initialvalue={initWarningTimeEnd}
              onInput={inputHandler}
            />
          </div>
          <Button
            variant="contained"
            color="primary"
            disabled={!formState.isValid}
            onClick={() => {
              if (apiCall === "create") {
                newNote({
                  sendRequest,
                  setKanbanData,
                  setLoadedData,
                  auth,
                  id,
                  noteText: formState.inputs?.noteText?.value as string,
                  noteType: formState.inputs?.type?.value as string,
                  warningDate: formState.inputs?.warning?.value
                    ? dateFormatFromISOString(
                        formState.inputs?.warningDate?.value as string
                      )
                    : "",
                  warningTimeStart: formState.inputs?.warningTimeStart
                    ?.value as string,
                  warningTimeEnd: formState.inputs?.warningTimeEnd
                    ?.value as string,
                });
              } else if (apiCall === "edit") {
                if (!scheduleListElement) {
                  editNote({
                    sendRequest,
                    setKanbanData,
                    setLoadedData,
                    auth,
                    sid: id,
                    nid,
                    scheduleListElement: false,
                    noteText: formState.inputs?.noteText?.value as string,
                    noteType: formState.inputs?.type?.value as string,
                    warningDate: formState.inputs?.warning?.value
                      ? dateFormatFromISOString(
                          formState.inputs?.warningDate?.value as string
                        )
                      : "",
                    warningTimeStart: formState.inputs?.warningTimeStart
                      ?.value as string,
                    warningTimeEnd: formState.inputs?.warningTimeEnd
                      ?.value as string,
                  });
                } else {
                  editNote({
                    sendRequest,
                    setKanbanData,
                    setLoadedData,
                    auth,
                    sid: id,
                    nid,
                    scheduleListElement: true,
                    noteText: formState.inputs?.noteText?.value as string,
                    noteType: formState.inputs?.type?.value as string,
                    warningDate: formState.inputs?.warning?.value
                      ? dateFormatFromISOString(
                          formState.inputs?.warningDate?.value as string
                        )
                      : "",
                    warningTimeStart: formState.inputs?.warningTimeStart
                      ?.value as string,
                    warningTimeEnd: formState.inputs?.warningTimeEnd
                      ?.value as string,
                  });
                }
              }
              setAnchorEl(null);
            }}
          >
            {apiCall === "create" ? "ADICIONAR" : "EDITAR"}
          </Button>
        </form>
      </Popover>
    </Fragment>
  );
};
