import React, {
  Suspense,
  useState,
  useEffect,
  useContext,
  Fragment,
} from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import { MuiThemeProvider } from "@material-ui/core/styles";

import { MainNavigation } from "./shared/components/Navigation/MainNavigation";
import UserLogin from "./user/pages/UserLogin";
import HomeUser from "./general/pages/authenticated/HomeUser";
import WaitingPage from "./general/pages/authenticated/WaitingPage";
import { appTheme } from "./shared/theme/appTheme";
import { AuthContext } from "./shared/context/authContext";
import { SettingsContext } from "./shared/context/settingsContext";
import { useAuth } from "./shared/hooks/authHook";
import { useSettings } from "./shared/hooks/settingsHook";
import { LoadingSpinnerFullScreenGear } from "./shared/components/UIElements/LoadingSpinnerFullScreenGear";
import { useHttpClient } from "./shared/hooks/httpHook";
import { fetchIsAppUpdated } from "./general/api/homePageUserAPI";
import { getCustomDomainRedirects } from "./website/api/websiteAPI";
import { APP_VERSION } from "./shared/data/static";

import "./App.css";
import "./shared/styles/FormInputsGrids.css";
import "./shared/styles/Utilities.scss";

const Admin1 = React.lazy(() => import("./admin/pages/Admin1"));
const Admin2 = React.lazy(() => import("./admin/pages/Admin2"));
const UserInfo = React.lazy(() => import("./user/pages/UserInfo"));
const UserPasswordExchange = React.lazy(
  () => import("./user/pages/UserPasswordExchange")
);
const UserPasswordReset = React.lazy(
  () => import("./user/pages/UserPasswordReset")
);
const CustomersList = React.lazy(
  () => import("./customer/pages/CustomersList")
);
const EditCustomer = React.lazy(() => import("./customer/pages/EditCustomer"));
const RegisterCustomer = React.lazy(
  () => import("./customer/pages/RegisterCustomer")
);
const RegisterOrEditKit = React.lazy(
  () => import("./kits/pages/RegisterOrEditKit")
);
const KitsList = React.lazy(() => import("./kits/pages/KitsList"));
const GeneratorsDashboard = React.lazy(
  () => import("./kits/pages/GeneratorsDashboard")
);
const ProposalsListAll = React.lazy(
  () => import("./proposal/pages/ProposalsListAll")
);
const ProposalListSingleCustomer = React.lazy(
  () => import("./proposal/pages/ProposalListSingleCustomer")
);
const ProposalStepOne = React.lazy(
  () => import("./proposal/pages/ProposalStepOne")
);
const ProposalStepTwoKitChoice = React.lazy(
  () => import("./proposal/pages/ProposalStepTwoKitChoice")
);
const ProposalStepTwoKitData = React.lazy(
  () => import("./proposal/pages/ProposalStepTwoKitData")
);
const ProposalStepThreePricingChoice = React.lazy(
  () => import("./proposal/pages/ProposalStepThreePricingChoice")
);
const ProposalStepThreeFinData = React.lazy(
  () => import("./proposal/pages/ProposalStepThreeFinData")
);
const ProposalStepFourTheme = React.lazy(
  () => import("./proposal/pages/ProposalStepFourTheme")
);
const ProposalFinalDigital = React.lazy(
  () => import("./proposal/pages/ProposalFinalDigital")
);
const ProposalFinalPDF = React.lazy(
  () => import("./proposal/pages/ProposalFinalPDF")
);
const ProposalFinalPrint = React.lazy(
  () => import("./proposal/pages/ProposalFinalPrint")
);
const ProposalFinalFinancing = React.lazy(
  () => import("./proposal/pages/ProposalFinalFinancing")
);
const CustomizationMenu = React.lazy(
  () => import("./customization/pages/CustomizationMenu")
);
const Tutorials = React.lazy(
  () => import("./general/pages/authenticated/Tutorials")
);
const About = React.lazy(() => import("./general/pages/unauthenticated/About"));
const CaptureForm = React.lazy(
  () => import("./general/pages/unauthenticated/CaptureForm")
);
const SignupStepOne = React.lazy(
  () => import("./general/pages/unauthenticated/SignupStepOne")
);
const SignupStepTwo = React.lazy(
  () => import("./general/pages/unauthenticated/SignupStepTwo")
);
const SignupStepThreeChoice = React.lazy(
  () => import("./general/pages/unauthenticated/SignupStepThreeChoice")
);
const SignupStepThreeCard = React.lazy(
  () => import("./general/pages/unauthenticated/SignupStepThreeCard")
);
const SinupFinishedCard = React.lazy(
  () => import("./general/pages/unauthenticated/SinupFinishedCard")
);
const SinupFinishedBill = React.lazy(
  () => import("./general/pages/unauthenticated/SinupFinishedBill")
);
const SinupFinishedTransfer = React.lazy(
  () => import("./general/pages/unauthenticated/SinupFinishedTransfer")
);
const Payments = React.lazy(
  () => import("./general/pages/authenticated/Payments")
);
const AccountSettings = React.lazy(
  () => import("./user/pages/AccountSettings")
);
const CancelPlan = React.lazy(
  () => import("./general/pages/authenticated/CancelPlan")
);
const Contact = React.lazy(() => import("./general/pages/Contact"));
const TermsOfServiceAndPrivacyPollicy = React.lazy(
  () =>
    import("./general/pages/unauthenticated/TermsOfServiceAndPrivacyPollicy")
);
const RegisterSale = React.lazy(() => import("./sales/pages/RegisterSale"));
const SalesFunnel = React.lazy(() => import("./sales/pages/SalesFunnel"));
const SalesData = React.lazy(() => import("./sales/pages/SalesData"));
const SchedulesList = React.lazy(() => import("./sales/pages/SchedulesList"));
const SalesListFinshed = React.lazy(
  () => import("./sales/pages/SalesListFinished")
);
const UpdatesList = React.lazy(
  () => import("./general/pages/authenticated/UpdatesList")
);
const EditManager = React.lazy(() => import("./manager/pages/EditManager"));
const ProposalPDFContentFastRendering = React.lazy(
  () =>
    import(
      "./proposal/content/finalProposalPDF/ProposalPDFContentFastRendering"
    )
);
const Website = React.lazy(() => import("./website/pages/Website"));
const WebsiteCustomization = React.lazy(
  () => import("./website/pages/WebsiteCustomization")
);
const ContractsList = React.lazy(
  () => import("./contract/pages/ContractsList")
);
const EditContract = React.lazy(() => import("./contract/pages/EditContract"));
// const GenerateContract = React.lazy(
//   () => import("./contract/pages/GenerateContract")
// );
const SalesContractsList = React.lazy(
  () => import("./contract/pages/SalesContractsList")
);
const ViewSaleContract = React.lazy(
  () => import("./contract/pages/ViewSaleContract")
);
const UploadCustomers = React.lazy(
  () => import("./customer/pages/UploadCustomers")
);
const UploadKits = React.lazy(() => import("./kits/pages/UploadKits"));
const Simulator = React.lazy(() => import("./website/pages/Simulator"));
const CustomerFiles = React.lazy(() => import("./archive/pages/CustomerFiles"));

export interface BeforeInstallPromptEvent extends Event {
  readonly platforms: Array<string>;
  readonly userChoice: Promise<{
    outcome: "accepted" | "dismissed";
    platform: string;
  }>;
  prompt(): Promise<void>;
}

function App() {
  const auth = useContext(AuthContext);
  const { sendRequest } = useHttpClient();
  const [addToHomescreenEvent, setAddToHomescreenEvent] =
    useState<BeforeInstallPromptEvent>(null);
  const [needUpdate, setNeedUpdate] = useState<boolean>(false);
  const [needCriticalUpdate, setNeedCriticalUpdate] = useState<boolean>(false);
  const [customDomainUserId, setCustomDomainUserId] = useState<string>("");
  const isStdDomain =
    window.location.hostname?.includes("azume.com.br") ||
    window.location.hostname?.includes("localhost") ||
    window.location.hostname?.includes("azume-capture-form.web") ||
    window.location.hostname?.includes("azume-teste.web") ||
    window.location.hostname?.includes("azume-serrana-teste.web");

  const {
    token,
    type,
    userId,
    managerId,
    managerUserId,
    vendors,
    userIsAdmin,
    statusOk,
    status,
    signupPlan,
    activeModules,
    whitelabel,
    login,
    logout,
  } = useAuth();

  window.addEventListener(
    "beforeinstallprompt",
    (event: BeforeInstallPromptEvent) => {
      event.preventDefault();
      const deferredPrompt = event;
      setAddToHomescreenEvent(deferredPrompt);
      return false;
    }
  );

  useEffect(() => {
    if (logout) {
      fetchIsAppUpdated({
        sendRequest,
        auth,
        logout,
        version: APP_VERSION,
        setNeedUpdate,
        setNeedCriticalUpdate,
      });
    }
  }, [logout]);

  useEffect(() => {
    if (
      window.location.hostname &&
      !window.location.hostname?.includes("localhost") &&
      !window.location.hostname?.includes("azume.com.br") &&
      !window.location.hostname?.includes("azume-capture-form.web") &&
      !window.location.hostname?.includes("azume-teste.web") &&
      !window.location.hostname?.includes("azume-serrana-teste.web")
    ) {
      getCustomDomainRedirects({
        sendRequest,
        setCustomDomainUserId,
        locationDomain: window.location.hostname,
      });
    }
  }, [window.location.hostname]);

  const {
    origins,
    salesLostReasons,
    notesTypes,
    autoPricingType,
    autoPricingCalc,
    autoPricing,
    autoPricingDetailed,
    autoFinancing,
    preferences,
    permissions,
    individualPermissions,
    proposalSlug,
    updateOriginsHandler,
    updateSalesLostReasonsHandler,
    updateNotesTypesHandler,
    updateAutoPricingTypeHandler,
    updateAutoPricingCalcHandler,
    updateAutoPricingHandler,
    updateAutoPricingDetailedHandler,
    updateAutoFinancingHandler,
    updatePreferencesHandler,
    updatePermissionsHandler,
    updateIndividualPermissionsHandler,
    updateProposalSlugHandler,
  } = useSettings();

  let routes: JSX.Element = null;

  if (token && !userIsAdmin && statusOk && isStdDomain) {
    routes = (
      <Switch>
        <Route path="/conta/info/:uid" exact>
          <div className="screen">
            <UserInfo />
          </div>
        </Route>
        <Route path="/conta/senha/:uid" exact>
          <div className="screen">
            <UserPasswordExchange />
          </div>
        </Route>
        <Route path="/clientes/lista/:uid" exact>
          <div className="screen">
            <CustomersList />
          </div>
        </Route>
        <Route path="/cliente/cadastro" exact>
          <div className="screen">
            <RegisterCustomer />
          </div>
        </Route>
        <Route path="/cliente/editar/:cid" exact>
          <div className="screen">
            <EditCustomer />
          </div>
        </Route>
        <Route path="/kits/lista/:uid" exact>
          <div className="screen">
            <KitsList screenContext="display" />
          </div>
        </Route>
        <Route
          path="/kits/escolha/:uid/:pid/:power/:powerNonPeak/:powerPeak/:demand"
          exact
        >
          <div className="screen">
            <KitsList screenContext="use" />
          </div>
        </Route>
        <Route
          path="/kits/fornecedores/:uid/:pid/:power/:powerNonPeak/:powerPeak/:demand"
          exact
        >
          <div className="screen">
            <GeneratorsDashboard />
          </div>
        </Route>
        <Route path="/kits/cadastro/:kitId" exact>
          <div className="screen">
            <RegisterOrEditKit />
          </div>
        </Route>
        <Route path="/kits/editar/:kitId" exact>
          <div className="screen">
            <RegisterOrEditKit />
          </div>
        </Route>
        <Route path="/propostas/lista/:uid" exact>
          <div className="screen">
            <ProposalsListAll />
          </div>
        </Route>
        <Route path="/propostas/cliente/:cid" exact>
          <div className="screen">
            <ProposalListSingleCustomer />
          </div>
        </Route>
        <Route path="/proposta/gerar-proposta/dados-consumo/nova" exact>
          <div className="screen">
            <ProposalStepOne />
          </div>
        </Route>
        <Route path="/proposta/gerar-proposta/dados-consumo/editar" exact>
          <div className="screen">
            <ProposalStepOne />
          </div>
        </Route>
        <Route path="/proposta/gerar-proposta/escolha-kit/:pid" exact>
          <div className="screen">
            <ProposalStepTwoKitChoice />
          </div>
        </Route>
        <Route path="/proposta/gerar-proposta/dados-geracao/nova/:pid" exact>
          <div className="screen">
            <ProposalStepTwoKitData />
          </div>
        </Route>
        <Route path="/proposta/gerar-proposta/escolha/precificacao/:pid" exact>
          <div className="screen">
            <ProposalStepThreePricingChoice />
          </div>
        </Route>
        <Route path="/proposta/gerar-proposta/preco-auto/:pid" exact>
          <div className="screen">
            <ProposalStepThreeFinData type="AUTO" />
          </div>
        </Route>
        <Route path="/proposta/gerar-proposta/preco-editar/:pid" exact>
          <div className="screen">
            <ProposalStepThreeFinData type="EDIT" />
          </div>
        </Route>
        <Route path="/proposta/gerar-proposta/personalizacao/:pid" exact>
          <div className="screen">
            <ProposalStepFourTheme />
          </div>
        </Route>
        <Route path="/proposta/final/pdf/:pid" exact>
          <ProposalFinalPDF />
        </Route>
        {/* <Route path="/proposta/final/preview/pdf" exact>
          <ProposalFinalPDF preview />
        </Route> */}
        <Route path="/proposta/final/impressao/:pid" exact>
          <ProposalFinalPrint />
        </Route>
        {/* <Route path="/proposta/final/preview/impressao" exact>
          <ProposalFinalPrint preview />
        </Route> */}
        <Route path="/proposta/final/financiamento/:pid" exact>
          <ProposalFinalFinancing />
        </Route>
        {/* <Route path="/proposta/final/preview/financiamento" exact>
          <ProposalFinalFinancing preview />
        </Route> */}
        <Route path="/proposta/final/mobile/:pid" exact>
          <ProposalFinalDigital />
        </Route>
        <Route path="/proposta/final/:pid" exact>
          <ProposalFinalDigital />
        </Route>
        <Route path="/proposta/final/:slug/:count/:code" exact>
          <ProposalFinalDigital />
        </Route>
        <Route path="/personalizar-proposta/:uid" exact>
          <div className="screen">
            <CustomizationMenu />
          </div>
        </Route>
        <Route path="/vendas/registro" exact>
          <div className="screen">
            <RegisterSale type="SALES" />
          </div>
        </Route>
        <Route path="/vendas/funil/:uid" exact>
          <div className="screen">
            <SalesFunnel type="SALES" />
          </div>
        </Route>
        <Route path="/vendas/dados/:uid" exact>
          <div className="screen">
            <SalesData
              addToHomescreenEvent={addToHomescreenEvent}
              setAddToHomescreenEvent={setAddToHomescreenEvent}
            />
          </div>
        </Route>
        <Route path="/vendas/agenda/:uid" exact>
          <div className="screen">
            <SchedulesList type="SALES" />
          </div>
        </Route>
        <Route path="/vendas/finalizadas" exact>
          <div className="screen">
            <SalesListFinshed type="SALES" />
          </div>
        </Route>
        <Route path="/projetos/registro" exact>
          <div className="screen">
            <RegisterSale type="PROJECT" />
          </div>
        </Route>
        <Route path="/projetos/quadro/:uid" exact>
          <div className="screen">
            <SalesFunnel type="PROJECT" />
          </div>
        </Route>
        <Route path="/projetos/agenda/:uid" exact>
          <div className="screen">
            <SchedulesList type="PROJECT" />
          </div>
        </Route>
        <Route path="/projetos/finalizadas" exact>
          <div className="screen">
            <SalesListFinshed type="PROJECT" />
          </div>
        </Route>
        <Route path="/instalacoes/registro" exact>
          <div className="screen">
            <RegisterSale type="INSTALLATION" />
          </div>
        </Route>
        <Route path="/instalacoes/quadro/:uid" exact>
          <div className="screen">
            <SalesFunnel type="INSTALLATION" />
          </div>
        </Route>
        <Route path="/instalacoes/agenda/:uid" exact>
          <div className="screen">
            <SchedulesList type="INSTALLATION" />
          </div>
        </Route>
        <Route path="/instalacoes/finalizadas" exact>
          <div className="screen">
            <SalesListFinshed type="INSTALLATION" />
          </div>
        </Route>
        <Route path="/pos-venda/registro" exact>
          <div className="screen">
            <RegisterSale type="AFTER_SALES" />
          </div>
        </Route>
        <Route path="/pos-venda/quadro/:uid" exact>
          <div className="screen">
            <SalesFunnel type="AFTER_SALES" />
          </div>
        </Route>
        <Route path="/pos-venda/agenda/:uid" exact>
          <div className="screen">
            <SchedulesList type="AFTER_SALES" />
          </div>
        </Route>
        <Route path="/pos-venda/finalizadas" exact>
          <div className="screen">
            <SalesListFinshed type="AFTER_SALES" />
          </div>
        </Route>
        <Route path="/atualizacoes" exact>
          <div className="screen">
            <UpdatesList />
          </div>
        </Route>
        <Route path="/colaboradores/:managerId" exact>
          <div className="screen">
            <EditManager />
          </div>
        </Route>
        <Route path="/tutoriais" exact>
          <div className="screen">
            <Tutorials />
          </div>
        </Route>
        <Route path="/financeiro" exact>
          <div className="screen">
            <Payments />
          </div>
        </Route>
        <Route path="/configuracoes" exact>
          <div className="screen">
            <AccountSettings />
          </div>
        </Route>
        <Route path="/cancelamento" exact>
          <div className="screen">
            <CancelPlan />
          </div>
        </Route>
        <Route path="/contato" exact>
          <div className="screen">
            <Contact inApp />
          </div>
        </Route>
        <Route path="/institucional/:id" exact>
          <Website />
        </Route>
        <Route path="/simulador/:id" exact>
          <Simulator />
        </Route>
        <Route path="/editar-website/:uid" exact>
          <div className="screen">
            <WebsiteCustomization />
          </div>
        </Route>
        <Route path="/contratos" exact>
          <div className="screen">
            <ContractsList />
          </div>
        </Route>
        <Route path="/escolher-contrato/:saleId" exact>
          <div className="screen">
            <ContractsList />
          </div>
        </Route>
        <Route path="/editar-contrato/:contractId" exact>
          <div className="screen">
            <EditContract />
          </div>
        </Route>
        {/* <Route path="/gerar-contrato/:contractId/:saleId" exact>
          <div className="screen">
            <GenerateContract />
          </div>
        </Route> */}
        <Route path="/visualizar-contratos-da-venda/:saleId" exact>
          <div className="screen">
            <SalesContractsList />
          </div>
        </Route>
        <Route path="/visualizar-contrato/:contractId/:saleId" exact>
          <div className="screen">
            <ViewSaleContract />
          </div>
        </Route>
        <Route path="/upload-de-clientes" exact>
          <div className="screen">
            <UploadCustomers />
          </div>
        </Route>
        <Route path="/upload-de-kits" exact>
          <div className="screen">
            <UploadKits />
          </div>
        </Route>
        <Route path="/upload-de-kits" exact>
          <div className="screen">
            <UploadKits />
          </div>
        </Route>
        <Route path="/arquivos/:cid" exact>
          <div className="screen">
            <CustomerFiles />
          </div>
        </Route>
        <Route path="/form-teste-gratis" exact>
          <CaptureForm />
        </Route>
        <Route path="/inicio" exact>
          <div className="screen">
            <HomeUser
              needCriticalUpdate={needCriticalUpdate}
              needUpdate={needUpdate}
            />
          </div>
        </Route>
        <Route path="/iniciando" exact>
          <div className="screen">
            <WaitingPage />
          </div>
        </Route>
        <Redirect to="/iniciando" />
      </Switch>
    );
  } else if (token && !userIsAdmin && !statusOk && isStdDomain) {
    routes = (
      <Switch>
        <Route path="/financeiro" exact>
          <div className="screen">
            <Payments />
          </div>
        </Route>
        <Route path="/configuracoes" exact>
          <div className="screen">
            <AccountSettings />
          </div>
        </Route>
        <Route path="/cancelamento" exact>
          <div className="screen">
            <CancelPlan />
          </div>
        </Route>
        <Route path="/contato" exact>
          <div className="screen">
            <Contact inApp />
          </div>
        </Route>
        <Route path="/form-teste-gratis" exact>
          <CaptureForm />
        </Route>
        <Route path="/clientes/lista/:uid" exact>
          <div className="screen">
            <CustomersList />
          </div>
        </Route>
        <Route path="/propostas/lista/:uid" exact>
          <div className="screen">
            <ProposalsListAll />
          </div>
        </Route>
        <Route path="/propostas/cliente/:cid" exact>
          <div className="screen">
            <ProposalListSingleCustomer />
          </div>
        </Route>
        <Route path="/kits/lista/:uid" exact>
          <div className="screen">
            <KitsList screenContext="display" />
          </div>
        </Route>
        <Route path="/proposta/final/pdf/:pid" exact>
          <ProposalFinalPDF />
        </Route>
        <Route path="/proposta/final/impressao/:pid" exact>
          <ProposalFinalPrint />
        </Route>
        <Route path="/proposta/final/financiamento/:pid" exact>
          <ProposalFinalFinancing />
        </Route>
        <Route path="/proposta/final/mobile/:pid" exact>
          <ProposalFinalDigital />
        </Route>
        <Route path="/proposta/final/:pid" exact>
          <ProposalFinalDigital />
        </Route>
        <Route path="/proposta/final/:slug/:count/:code" exact>
          <ProposalFinalDigital />
        </Route>
        <Route path="/inicio" exact>
          <div className="screen">
            <HomeUser
              needCriticalUpdate={needCriticalUpdate}
              needUpdate={needUpdate}
            />
          </div>
        </Route>
        <Route path="/iniciando" exact>
          <div className="screen">
            <WaitingPage />
          </div>
        </Route>
        <Redirect to="/iniciando" />
      </Switch>
    );
  } else if (isStdDomain) {
    routes = (
      <Switch>
        <Route path="/login" exact>
          <div className="screen">
            <UserLogin />
          </div>
        </Route>
        <Route path="/admin1" exact>
          <div className="screen">
            <Admin1 />
          </div>
        </Route>
        <Route path="/admin2" exact>
          <div className="screen">
            <Admin2 />
          </div>
        </Route>
        <Route path="/recuperacao-de-acesso" exact>
          <div className="screen">
            <UserPasswordReset />
          </div>
        </Route>
        <Route path="/sobre" exact>
          <div className="screen screen--no-side-menu">
            <About />
          </div>
        </Route>
        <Route path="/form-teste-gratis" exact>
          <CaptureForm />
        </Route>
        <Route path="/contato" exact>
          <div className="screen">
            <Contact />
          </div>
        </Route>
        <Route path="/termos-de-uso-e-politica-de-privacidade" exact>
          <div className="screen">
            <TermsOfServiceAndPrivacyPollicy />
          </div>
        </Route>
        <Route path="/sobre/mobile" exact>
          <About />
        </Route>
        <Route path="/assinatura/passo-1/:planId" exact>
          <SignupStepOne />
        </Route>
        {/* <Route path="/assinatura/passo-2/:planId/:suId" exact>
          <SignupStepTwo />
        </Route> */}
        <Route path="/assinatura/escolha-pagamento/:planId/:suId" exact>
          <SignupStepThreeChoice />
        </Route>
        <Route path="/assinatura/passo-3/:planId/:suId" exact>
          <SignupStepThreeCard />
        </Route>
        <Route path="/assinatura/parabens/:planId/:suId" exact>
          <SinupFinishedCard />
        </Route>
        <Route path="/assinatura/boleto/parabens/:suId" exact>
          <SinupFinishedBill />
        </Route>
        <Route path="/assinatura/pix/parabens/:suId" exact>
          <SinupFinishedTransfer />
        </Route>
        <Route path="/proposta/final/pdf/:pid" exact>
          <ProposalFinalPDF />
        </Route>
        {/* <Route path="/proposta/final/preview/pdf" exact>
          <ProposalFinalPDF preview />
        </Route> */}
        <Route path="/proposta/final/impressao/:pid" exact>
          <ProposalFinalPrint />
        </Route>
        {/* <Route path="/proposta/final/preview/impressao" exact>
          <ProposalFinalPrint preview />
        </Route> */}
        <Route path="/proposta/final/financiamento/:pid" exact>
          <ProposalFinalFinancing />
        </Route>
        {/* <Route path="/proposta/final/preview/financiamento" exact>
          <ProposalFinalFinancing preview />
        </Route> */}
        <Route path="/proposta/final/mobile/:pid" exact>
          <ProposalFinalDigital />
        </Route>
        <Route path="/proposta/final/:pid" exact>
          <ProposalFinalDigital />
        </Route>
        <Route path="/proposta/final/:slug/:count/:code" exact>
          <ProposalFinalDigital />
        </Route>
        <Route path="/institucional/:id" exact>
          <Website />
        </Route>
        <Route path="/simulador/:id" exact>
          <Simulator />
        </Route>
        <Route path="/financeiro" exact>
          <div className="screen">
            <Payments />
          </div>
        </Route>
        <Route path="/arquivos/:cid" exact>
          <div className="screen">
            <CustomerFiles />
          </div>
        </Route>
        <Redirect
          to={!!localStorage?.getItem("loginPsd") ? "/login" : "/sobre"}
        />
      </Switch>
    );
  } else if (!isStdDomain) {
    routes = (
      <Switch>
        <Route path="/institucional/:id" exact>
          <Website />
        </Route>
        <Redirect to={`/institucional/${customDomainUserId}`} />
      </Switch>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token && !!userId && !!status,
        userId: userId,
        managerId: managerId,
        managerUserId: managerUserId,
        vendors: vendors,
        token: token,
        type: type,
        userIsAdmin: userIsAdmin,
        statusOk: statusOk,
        status: status,
        signupPlan: signupPlan,
        activeModules: activeModules,
        whitelabel: whitelabel,
        login: login,
        logout: logout,
      }}
    >
      <SettingsContext.Provider
        value={{
          origins,
          salesLostReasons,
          notesTypes,
          autoPricingType,
          autoPricingCalc,
          autoPricing,
          autoPricingDetailed,
          autoFinancing,
          preferences,
          permissions,
          individualPermissions,
          proposalSlug,
          updateOriginsHandler,
          updateSalesLostReasonsHandler,
          updateNotesTypesHandler,
          updateAutoPricingTypeHandler,
          updateAutoPricingCalcHandler,
          updateAutoPricingHandler,
          updateAutoPricingDetailedHandler,
          updateAutoFinancingHandler,
          updatePreferencesHandler,
          updatePermissionsHandler,
          updateIndividualPermissionsHandler,
          updateProposalSlugHandler,
        }}
      >
        <MuiThemeProvider theme={appTheme}>
          <Router>
            {/* <NavContext.Provider
            value={{
              activeView: activeView,
              activeUrl: activeUrl,
              navigateHandler: navigateHandler,
            }}
          > */}
            <MainNavigation
              needUpdate={needUpdate}
              needCriticalUpdate={needCriticalUpdate}
              addToHomescreenEvent={addToHomescreenEvent}
              setAddToHomescreenEvent={setAddToHomescreenEvent}
            />
            <main>
              {(window.location.hostname?.includes("azume") ||
                window.location.hostname?.includes("localhost")) &&
                !window.location.href?.includes("/institucional") &&
                !window.location.href?.includes("/proposta/final") && (
                  <Helmet>
                    <link
                      rel="icon"
                      href={`${process.env.PUBLIC_URL}/azume-icon-48x48.png`}
                    />
                    <title>Azume - CRM Solar</title>
                    <meta
                      name="description"
                      content="Azume CRM Solar - Turbine o faturamento da sua empresa de Energia Solar com o Azume!"
                    />
                    <meta property="og:url" content="https://azume.com.br" />
                    <meta property="og:type" content="website" />
                    <meta
                      property="og:image"
                      content={`${process.env.PUBLIC_URL}/azume-open-graph-logo.png`}
                    />
                    <meta property="og:title" content="Azume - CRM Solar<" />
                    <meta
                      property="og:description"
                      content="Azume CRM Solar - Turbine o faturamento da sua empresa de Energia Solar com o Azume!"
                    />
                  </Helmet>
                )}
              {window.location.href?.includes("/proposta/final") && (
                <Helmet>
                  {/* <link
                  rel="icon"
                  href={`${process.env.PUBLIC_URL}/favicon.ico`}
                /> */}
                  <title>Proposta de Energia Solar Fotovoltaica</title>
                  <meta
                    name="description"
                    content="Chegou a sua proposta comercial para o sistema de Energia Solar Fotovoltaica. Clique e veja!"
                  />
                  <meta property="og:type" content="website" />
                  <meta
                    property="og:image"
                    content={`${process.env.PUBLIC_URL}/azume-og-image.jpg`}
                  />
                  <meta
                    property="og:title"
                    content="Sua Proposta de Energia Solar Fotovoltaica"
                  />
                  <meta
                    property="og:description"
                    content="Chegou a sua proposta comercial para o sistema de Energia Solar Fotovoltaica. Clique e veja!"
                  />
                </Helmet>
              )}
              <Suspense fallback={<LoadingSpinnerFullScreenGear fallback />}>
                <Fragment>
                  <Fragment>{routes}</Fragment>
                  {preferences?.proposalPDF?.useFastPDFRendering && (
                    <ProposalPDFContentFastRendering />
                  )}
                </Fragment>
              </Suspense>
            </main>
            {/* </NavContext.Provider> */}
          </Router>
        </MuiThemeProvider>
      </SettingsContext.Provider>
    </AuthContext.Provider>
  );
}

export default App;
