import { Fragment, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import Collapse from "@material-ui/core/Collapse";
import MoreHorizRoundedIcon from "@material-ui/icons/MoreHorizRounded";

import { useHttpClient } from "../../shared/hooks/httpHook";
import { ModalError } from "../../shared/components/UIElements/ModalError";
import { MenuSalesNotesOptions } from "./MenuSalesNotesOptions";
import { LoadingSpinnerOverlayRegular } from "../../shared/components/UIElements/LoadingSpinnerOverlayRegular";
import {
  NoteData,
  AuthContextProps,
  KanbanFilledData,
  NotePopCustData,
} from "../../shared/data/types";

import "./SaleDashNoteItem.scss";

interface SaleDashNoteItemProps {
  auth: AuthContextProps;
  saleId: string;
  customerId: string;
  loadedNote: NoteData;
  activeNote: string;
  setLoadedData: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
  setScheduleData?: React.Dispatch<React.SetStateAction<NotePopCustData[]>>;
}

export const SaleDashNoteItem = (props: SaleDashNoteItemProps) => {
  const {
    loadedNote,
    auth,
    saleId,
    customerId,
    setLoadedData,
    activeNote,
    setScheduleData,
  } = props;
  const { sendRequest, isLoading, error, clearError } = useHttpClient();
  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>(null);
  const [anchorElForm, setAnchorElForm] = useState<
    null | HTMLLIElement | HTMLButtonElement
  >(null);
  const timeStart =
    loadedNote?.notificationTimeStart?.split("T")?.[1]?.substring(0, 5) ||
    "00:00";
  const timeEnd =
    loadedNote?.notificationTimeEnd?.split("T")?.[1]?.substring(0, 5) ||
    "00:00";
  const startAndEndEqual = timeStart === timeEnd;

  return (
    <Fragment>
      <div
        className={`sale-prop-item ${
          activeNote === loadedNote?._id ? "sale-prop-item--highlighted" : ""
        }`}
        onClick={(e) => {
          if (!anchorEl && !anchorElForm) {
            setOpen(!open);
          }
        }}
      >
        <ModalError error={error} onClear={clearError} />
        <MenuSalesNotesOptions
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          anchorElForm={anchorElForm}
          setAnchorElForm={setAnchorElForm}
          saleId={saleId}
          customerId={customerId}
          note={loadedNote}
          sendRequest={sendRequest}
          setKanbanData={setLoadedData}
          setScheduleData={setScheduleData}
          auth={auth}
        />
        {isLoading && <LoadingSpinnerOverlayRegular image color="primary" />}
        <div className="sale-prop-note-item__data">
          <p className="sale-prop-note-item__data-item sale-prop-note-item__data-item--thin">
            {loadedNote?.content}
          </p>
        </div>
        <div className="sale-prop-note-item__controls">
          <IconButton
            size="small"
            title={open ? "Ver Mais" : "Fechar"}
            className={`table__item-action${open ? "--open" : ""}`}
            onClick={(e) => {
              if (!anchorEl && !anchorElForm) {
                setOpen(!open);
                e.stopPropagation();
              }
            }}
          >
            <ExpandMoreRoundedIcon style={{ fontSize: "22px" }} />
          </IconButton>
          <IconButton
            size="small"
            title="Opções"
            className={`table__item-action`}
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
              e.stopPropagation();
            }}
          >
            <MoreHorizRoundedIcon style={{ fontSize: "22px" }} />
          </IconButton>
        </div>
        <div className="sale-prop-note-item__details">
          <Collapse in={open}>
            <div className="sale-prop-note-item__details-content">
              {!!loadedNote?.type && (
                <p className="sale-prop-note-item__data-item sale-prop-note-item__data-item--thin">
                  Tipo de Nota: {loadedNote.type}
                </p>
              )}
              {!!loadedNote?.notificationDate && (
                <p className="sale-prop-note-item__data-item sale-prop-note-item__data-item--thin">
                  Agendado para: {loadedNote?.notificationDate}{" "}
                  {startAndEndEqual
                    ? `às ${timeStart || "00:00"}`
                    : `de ${timeStart} às ${timeEnd}`}
                </p>
              )}
              <p className="sale-prop-note-item__data-item sale-prop-note-item__data-item--thin">
                Registro: <span>{loadedNote?.registryDate || ""}</span>
              </p>
            </div>
          </Collapse>
        </div>
      </div>
    </Fragment>
  );
};
