import React, { useReducer, useEffect } from "react";

import { TextFieldSmallFontSize } from "../MUIThemed/TextFieldSmallFontSize";
import { InputDispState, InputDispAction } from "./Input";
import { validate } from "../../util/validators";

const inputReducer = (state: InputDispState, action: InputDispAction) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.val,
        isValid: validate(action.val, action.validators),
      };
    case "TOUCH":
      return {
        ...state,
        isTouched: true,
      };
    case "UNTOUCH":
      return {
        ...state,
        isTouched: false,
      };
    default:
      return state;
  }
};

interface InputWhatsAppBtnProps {
  id: string;
  label: string;
  onInput: (id: string, value: string, isValid: boolean, label: string) => void;
  helperText: string;
  type: string;
  initialValue?: string;
  initialValid?: boolean;
  variant?: "standard" | "filled" | "outlined";
  multiline?: boolean;
  rows?: number;
  placeholder?: string;
  disabled?: boolean;
  reset?: boolean;
  reinitialize?: boolean;
  validators: { type: string; val?: number }[];
}

export const InputWhatsAppBtn = (props: InputWhatsAppBtnProps) => {
  const {
    id,
    label,
    onInput,
    helperText,
    type,
    initialValue,
    initialValid,
    variant,
    multiline,
    rows,
    placeholder,
    disabled,
    validators,
    reinitialize,
    reset,
  } = props;
  const [inputState, dispatch] = useReducer(inputReducer, {
    value: initialValue || "",
    isTouched: false,
    isValid: initialValid || false,
  });
  const { value, isValid, isTouched } = inputState;

  useEffect(() => {
    onInput(id, value, isValid, label.charAt(0).toUpperCase() + label.slice(1));
  }, [id, value, isValid, label, onInput]);

  useEffect(() => {
    if (reset) {
      dispatch({
        type: "CHANGE",
        val: "",
        validators: validators,
      });
      dispatch({
        type: "UNTOUCH",
      });
    }
    if (reinitialize) {
      dispatch({
        type: "CHANGE",
        val: initialValue,
        validators: validators,
      });
      dispatch({
        type: "UNTOUCH",
      });
    }
  }, [reset, reinitialize, initialValue]);

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: "CHANGE",
      val: event.target.value,
      validators: validators,
    });
  };

  const touchHandler = () => {
    dispatch({ type: "TOUCH" });
  };

  return (
    <TextFieldSmallFontSize
      style={{ fontSize: "0.8rem" }}
      disabled={disabled}
      error={!isValid && isTouched}
      id={id}
      name={id}
      variant={variant}
      label={label.charAt(0).toUpperCase() + label.slice(1)}
      helperText={!isValid && isTouched && helperText}
      multiline={multiline}
      rows={multiline && rows}
      placeholder={placeholder}
      InputProps={{ style: { fontSize: "0.8rem" } }}
      inputProps={{
        onChange: changeHandler,
        onBlur: touchHandler,
        value: value,
        type: type,
      }}
    />
  );
};
